// export default function replaceDivWithNewline(htmlString) {
//     // Replace <div> with newlines
//     let result = htmlString.replace(/<div>(.*?)<\/div>/g, (match, content) => {
//       // If <div> contains a <br> tag or is empty, replace with a newline
//       if (content.trim() === '<br>' || content.trim() === '') {
//         return '\n';
//       }
//       // Otherwise, return the content followed by a newline
//       return content + '\n';
//     });

//     console.log('result', result)

//     return result;
//   }

// Example usage

export default function replaceDivsWithNewlines(htmlContent) {
  console.log('htmlContent', htmlContent)
  var newContent = htmlContent
    ?.replace(/<div>(.*?)<\/div>/g, "$1\n") // Replace <div> with newlines
    ?.replace(/<br\s*\/?>/g, "\n") // Replace <br> with newlines
    ?.replace(/<\/?[^>]+(>|$)/g, ""); // Remove any remaining HTML tags

  // Add a newline after "Sample" if it's the first element
  if (newContent?.startsWith("Sample")) {
    newContent = "Sample\n" + newContent.substring(6);
  }

  return newContent;
}
