import replaceDivsWithNewlines from "../helper/contentString";

export const generateDOM = (data) => {
  const {
    lines = [],
    textAreas = [],
    images = [],
    url: bgImageUrl,
    from = [],
  } = {
    ...data[0],
    ...data[1],
    ...data[2],
    ...data[3],
    ...data[5],
  };

  const zoomValue = data[4]?.zoomLevel || 1;
  const containerWidth = data[4]?.width || 892; // fallback width
  const containerHeight = data[4]?.height || 591; // fallback height

  // Set up container style to match preview scaling
  const containerStyle = {
    position: "relative",
    width: `${containerWidth * zoomValue}px`, // Scaled width
    height: `${containerHeight * zoomValue}px`, // Scaled height
    backgroundImage: bgImageUrl
      ? `url("${bgImageUrl}")`
      : 'url("/images/certBg.jpg")',
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  const container = document.createElement("div");
  Object.assign(container.style, containerStyle);

  // Adjust line elements based on scaling
  const createLineDiv = ({ position, width, color, dotted }) => {
    const div = document.createElement("div");
    Object.assign(div.style, {
      position: "absolute",
      left: `${position.x * zoomValue}px`,
      top: `${position.y * zoomValue}px`,
      width: `${width * zoomValue}px`,
      height: `2px`,
      backgroundColor: dotted ? "transparent" : color,
      borderTop: dotted ? "2px dashed" : "none",
      borderTopColor: color || "black",
    });
    return div;
  };

  // Adjust text areas based on scaling
  const createTextDiv = ({
    content,
    position,
    fontFamily,
    fontSize,
    fontColor,
    textAlign,
    isBold,
    isItalic,
    isUnderline,
    width,
  }) => {
    const textDiv = document.createElement("div");
    textDiv.textContent =
    content === "[recipient.name]"
      ? data[5]?.type === "saveAs"
        ? replaceDivsWithNewlines(content)
        : replaceDivsWithNewlines(data[5]?.details?.name)
      : replaceDivsWithNewlines(content); // or another default if needed
  console.log("template data textarea width", width * zoomValue);
  console.log(
    "fontSize * zoomValue",
    parseInt(fontSize?.replace("px", "")),
    zoomValue,
    "wordWrap",
    "break-word", // Wrap words that exceed the width

    parseInt(fontSize?.replace("px", "")) * zoomValue
  );
  console.log("position.x", position.x * zoomValue);
    Object.assign(textDiv.style, {
      position: "absolute",
      left: `${position.x * zoomValue}px`,
      top: `${position.y * zoomValue}px`,
      width: `${width * zoomValue}px`,
      fontFamily,
      fontSize: `${parseInt(fontSize) * zoomValue}px`,
      color: fontColor,
      textAlign,
      fontWeight: isBold ? "bold" : "normal",
      fontStyle: isItalic ? "italic" : "normal",
      textDecoration: isUnderline ? "underline" : "none",
      whiteSpace: "pre-wrap",
      overflow: "hidden",
      zIndex: 30000,
    });
    return textDiv;
  };

  // Adjust images based on scaling
  const createImage = ({ url, x, y, width, height }) => {
    const imgElem = document.createElement("img");
    imgElem.crossOrigin = "anonymous"; // Add this line
    imgElem.src = url;
    imgElem.onload = () => {
      console.log(`Image loaded: ${url}`);
    };

    imgElem.onerror = (error) => {
      console.error(`Failed to load image: ${url}`, error);
    };
    Object.assign(imgElem.style, {
      position: "absolute",
      left: `${x * zoomValue}px`,
      top: `${y * zoomValue}px`,
      width: `${width * zoomValue}px`,
      height: `${height * zoomValue}px`,
    });
    return imgElem;
  };

  // Append elements to the container
  lines.forEach((line) => container.appendChild(createLineDiv(line)));
  lines.forEach((line) => console.log("line", line));

  // Append text areas

  console.log("textAreas", textAreas);

  const filteredTextAreas = textAreas.filter(
    (item) =>
      ((item.defaultField || item.customField) && item.enabled) ||
      (!item.defaultField && !item.customField)
  );

  console.log(filteredTextAreas);

  filteredTextAreas.forEach((textArea) =>
    container.appendChild(createTextDiv(textArea))
  );

  // Append images
  console.log("imagews via append", images);
  images.forEach((image) => console.log("image ------>", image));

  images.forEach((image) => container.appendChild(createImage(image)));

  return container;
};
