import { jwtDecode } from "jwt-decode";

// Function to check if the token is expired
export const isTokenExpired = (token) => {
  if (!token) return true; // If there's no token, consider it expired

  try {
    const { exp } = jwtDecode(token); // Decode the token to get the expiration time
    if (!exp) return true;

    const currentTime = Date.now() / 1000; // Current time in seconds
    return exp < currentTime; // Check if the token is expired
  } catch (error) {
    console.error("Error decoding token:", error);
    return true; // Consider it expired if there's an error decoding
  }
};


