import React, { useEffect, useRef, useState } from "react";
import interact from "interactjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setContent,
  setPosition,
  selectTextArea,
  removeTextArea,
  setDimension,
} from "../../store/textEditorSlice";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { setSelectId } from "../../store/ImageSlice";
import {
  DraggableResizableContainer,
  ResizeHandle,
} from "../../style/globalStyle";
import { setSelectedLineId } from "../../store/lineSlice";
import { ContentEditable } from "./components/EditableDiv";
import { getDynamicMessage } from "../../constant/messages";

const DraggableResizableText = ({ renderedDimensions }) => {
  const dispatch = useDispatch();
  const zoomLevel = useSelector((state) => state.bgUploads.zoomLevel);

  const [isDivEditable, setIsDivEditable] = useState(false);
  const { textAreas, selectedId } = useSelector((state) => state.textEditor);
  const selectedTextArea =
    textAreas.findIndex((textArea) => textArea.id === selectedId) || {};

  console.log("selectedIdSUe", selectedId);

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const divRef = useRef(null);
  useEffect(() => {
    const parentElement = containerRef.current;
    console.log("isDivEditable from dragg", isDivEditable);

    if (parentElement) {
      interact(".draggable-resizable-text")
        .draggable({
          listeners: {
            start() {
              setIsDragging(true); // Set dragging state to true when drag starts
            },
            move(event) {
              if (!isDivEditable) {
                const id = event.target.dataset.id;

                // Adjust drag movement by dividing by zoom level
                const adjustedDx = event.dx / zoomLevel;
                const adjustedDy = event.dy / zoomLevel;

                const newX = parseFloat(event.target.dataset.x) + adjustedDx;
                const newY = parseFloat(event.target.dataset.y) + adjustedDy;

                // Dispatch new coordinates to Redux
                dispatch(setPosition({ id, x: newX, y: newY }));

                // Update the element's data attributes
                event.target.dataset.x = newX;
                event.target.dataset.y = newY;

                // Apply transform to the element
                event.target.style.transform = `translate(${newX}px, ${newY}px)`;
              }
            },
            end() {
              setIsDragging(false); // Set dragging state to false when drag ends
            },
          },
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: parentElement, // Restrict dragging within the parent
              elementRect: { top: 0, left: 0, bottom: 1, right: 1 }, // Adjust element edges
              endOnly: true, // Apply restriction only when dragging ends
            }),
          ],
        })
        .resizable({
          edges: { left: true, right: true, bottom: false, top: false },
          listeners: {
            move(event) {
              const id = event.target.dataset.id;

              console.log("----id", id);
              const { x, y } = event.target.dataset;

              // Adjust resize movement by dividing by zoom level
              const adjustedWidth = event.rect.width / zoomLevel;

              // Dispatch new size and position to Redux
              dispatch(
                setPosition({
                  id,
                  x: parseFloat(x) + event.deltaRect.left / zoomLevel,
                  y: parseFloat(y),
                })
              );

              dispatch(
                setDimension({
                  id,
                  width: adjustedWidth,
                })
              );

              // Update the element's style and data attributes
              event.target.style.width = `${adjustedWidth}px`;
              event.target.dataset.width = adjustedWidth;
            },
          },
          modifiers: [
            interact.modifiers.restrictSize({
              min: { width: 100, height: 60 },
              max: { width: parentElement.offsetWidth },
            }),
            interact.modifiers.restrictEdges({
              outer: parentElement, // Restrict edges within the parent
            }),
          ],
        });
    }
  }, [dispatch, isDivEditable, zoomLevel]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const element = document.querySelector(`[data-id="${selectedId}"]`);
      const parentElement = containerRef.current;

      if (!element || !parentElement || isDivEditable) return;

      const step = 10; // Amount of pixels to move on each key press

      // Get the current position and size of the element
      const currentX = parseFloat(element.dataset.x) || 0;
      const currentY = parseFloat(element.dataset.y) || 0;
      const elementWidth = element.offsetWidth;
      const elementHeight = element.offsetHeight;

      // Get the parent container's size
      const parentRect = parentElement.getBoundingClientRect();

      // Define new positions based on key presses
      let newX = currentX;
      let newY = currentY;

      switch (event.key) {
        case "ArrowUp":
          newY = currentY - step;
          break;
        case "ArrowDown":
          newY = currentY + step;
          break;
        case "ArrowLeft":
          newX = currentX - step;
          break;
        case "ArrowRight":
          newX = currentX + step;
          break;
        default:
          return; // Exit if the key pressed is not an arrow key
      }

      // Boundary checks: prevent moving beyond the parent container
      if (newX < 0) newX = 0; // Prevent moving beyond the left edge
      if (newY < 0) newY = 0; // Prevent moving beyond the top edge
      if (newX + elementWidth > parentRect.width)
        newX = parentRect.width - elementWidth; // Prevent moving beyond the right edge
      if (newY + elementHeight > parentRect.height)
        newY = parentRect.height - elementHeight; // Prevent moving beyond the bottom edge

      // Update the element's position and state
      element.dataset.x = newX;
      element.dataset.y = newY;
      element.style.transform = `translate(${newX}px, ${newY}px)`;

      console.log("selectedId------>>>>>>", selectedId);
      console.log("newX,newY", newX, newY, selectedId);

      // Dispatch new position to Redux if needed

      dispatch(setPosition({ id: selectedId, x: newX, y: newY }));
    };

    // Attach keydown listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, isDivEditable, selectedId, textAreas]);

  const handleTextAreaClick = (index, id) => {
    // Ensure we don't trigger click when deleting an item
    dispatch(selectTextArea({ index: index, id: id }));
  };

  const handleParentClick = () => {
    console.log("clicked from outside"); // Clicked outside the div
    dispatch(selectTextArea({ index: null, id: null }));
    dispatch(setSelectId(null));
    dispatch(setSelectedLineId(null));
    setIsDivEditable(false);
  };

  const handleChildClick = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up to the parent
    console.log("clicked from inside");
    //Making null the image or other selected elements
    dispatch(setSelectId(null));
    dispatch(setSelectedLineId(null));
  };
  const updateParentHeight = (id) => {
    if (divRef.current) {
      console.log("id", id);
      // Calculate total height of the parent based on the children (textAreas)
      const currentWidth = divRef.current.scrollWidth;
      console.log("cuurentWidth", currentWidth);

      dispatch(
        setDimension({
          id,
          currentWidth,
        })
      );
    }
  };

  const handleChange = (id, newContent) => {
    dispatch(setContent({ id: id, content: newContent }));
  };
  console.log("textAreas", textAreas);

  return (
    <DraggableResizableContainer
      isEditableDiv={isDivEditable}
      onClick={handleParentClick}
      ref={containerRef}
    >
      {textAreas
        .filter(
          (textArea) =>
            (!textArea.defaultField ||
              (textArea.defaultField && textArea.enabled)) &&
            (!textArea.customField ||
              (textArea.customField && textArea.enabled))
        )
        .map((textArea, index) => (
          <Tooltip
            key={textArea.id}
            placement="left"
            visible={
              !textArea?.defaultField &&
              hoveredIndex === textArea.id &&
              textArea.elementReadOnly &&
              !isDragging
            }
            title={getDynamicMessage(textArea.content)}
          >
            <div
              ref={divRef}
              data-index={index}
              data-x={textArea.position?.x || 0}
              data-id={textArea.id}
              data-y={textArea.position?.y || 0}
              onMouseEnter={() => setHoveredIndex(textArea.id)}
              onMouseLeave={() => setHoveredIndex(null)}
              className="draggable-resizable-text"
              onClick={(e) => {
                updateParentHeight();
                handleTextAreaClick(index, textArea.id);
                handleChildClick(e);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                transform: `translate(${textArea.position?.x || 0}px, ${
                  textArea.position?.y || 0
                }px)`,
                width: textArea?.width > 0 ? textArea.width : "auto",
                border:
                  hoveredIndex === textArea.id
                    ? "2px solid blue"
                    : selectedId === textArea?.id
                    ? "2px solid blue"
                    : null,
                boxSizing: "border-box",
                cursor: "text !important",
                zIndex: 30000,
              }}
            >
              <ContentEditable
                textArea={textArea}
                html={textArea.content}
                onChange={(newContent) => handleChange(textArea.id, newContent)}
                setIsDivEditable={setIsDivEditable}
                isDivEditable={isDivEditable}
                renderedDimensions={renderedDimensions}
              />

              {/* Resize handles on the left and right */}
              {textArea?.id === selectedId && (
                <>
                  <ResizeHandle
                    style={{ left: -6, cursor: "w-resize" }}
                    className="resize-handle"
                  />
                  <ResizeHandle
                    style={{ right: -6, cursor: "e-resize" }}
                    className="resize-handle"
                  />
                  {!textArea.elementReadOnly &&
                    !textArea.defaultField &&
                    !textArea.customField && (
                      <Button
                        className="draggable-delete-btn"
                        onClick={() => {
                          dispatch(removeTextArea(textArea.id));
                          dispatch(selectTextArea({ id: null, index: null }));
                          setHoveredIndex(null);
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    )}
                </>
              )}
            </div>
          </Tooltip>
        ))}
    </DraggableResizableContainer>
  );
};

export default DraggableResizableText;
