export const subString = (str) => {
  let firstStr = str?.substring(0, 5);
  let lastStr = str?.substring(str.length - 5);
  let res = firstStr + "....." + lastStr;
  return res;
};

export const subStringForTextArea = (str) => {
  if (str?.length > 100) {
    let firstStr = str?.substring(0, 100);
    // let lastStr = str?.substring(str.length - 40);
    let res = firstStr + ".....";
    return res;
  }
  return str;
};

export default function replaceSpacesWithUnderscore(str) {
  return str?.replace(/\s+/g, "_");
}

export const capitalizeFirstLetter = (str) => {
  if (!str) return ""; // Check if the string is empty or null
  return str.charAt(0).toUpperCase() + str.slice(1);
};
