import { Button, Form } from "antd";
import styled from "styled-components";

export const TextArea = styled.textarea`
  resize: none;
  width: 100% !important;
  /* height: ${({ height }) => height || "200px"}; */
  font-family: ${({ fontFamily }) => fontFamily || "Arial, sans-serif"};
  font-size: ${({ fontSize }) => fontSize || "16px"};
  font-weight: ${({ isBold }) => (isBold ? "bold" : "normal")};
  font-style: ${({ isItalic }) => (isItalic ? "italic" : "normal")};
  text-decoration: ${({ isUnderline }) => (isUnderline ? "underline" : "none")};
  color: ${({ fontColor }) => fontColor || "#000"};
  background-color: ${({ backgroundColor }) => backgroundColor || "#fff"};
  text-align: ${({ textAlign }) => textAlign || "left"};
  /* padding-top: 2rem; */
  border: 1px solid #ccc;
  outline: none;
  cursor: ${({ cursor }) => cursor || ""};
  border: ${({ border }) => border || ""};
  line-height: ${({ lineHeight }) => `${lineHeight}px` || ""};

  /* line-height:200px */
`;

export const EditorContainer = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  margin-top: 10px;
`;

export const ResizeHandle = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border: 1px solid grey;
  position: absolute;
  border-radius: 50%;
  z-index: 1000;
`;

export const DraggableResizableContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* z-index: 30000; */
  .draggable-delete-btn {
    width: 32px;
    border-radius: 22px;
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 28px;
    position: absolute;
    bottom: -32px;
    right: 0;
  }
  .draggable-resizable-text {
    &:hover {
      cursor: ${(props) => (props.isEditableDiv ? "text" : "move")} !important;
    }
  }
`;

export const StyledForm = styled(Form)`
  padding: ${(props) => props.padding || "20px"};
  /* padding: 20px; */
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${(props) => props.paddingTop || "40px"};
  .forget-para {
    font-family: Inter;
    cursor: pointer;
    text-align: end;
    color: #069;
    width: 80%;
  }
  .ant-form-item {
    width: 80%;
  }
  input,
  .ant-input-affix-wrapper {
    border-radius: 8px;
    border: 1px solid #d8e1ed;
    background: rgba(255, 255, 255, 0.7);
    height: 56px;
    color: #3b4856 !important;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  input::placeholder,
  .ant-input-affix-wrapper::placeholder {
    color: #3b4856;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-input-affix-wrapper {
    padding: 0px 11px !important;
  }

  .ant-form-item-label,
  .ant-form-item-required {
    color: #777e91 !important;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-weight: bold;
  }
  .ant-form-item-explain-error {
    text-align: left;
  }

  .ant-upload-list-item {
    border-radius: 10px;
    padding: 20px 20px;
    background: rgba(216, 225, 237, 0.5);
  }
  .ant-upload-icon .anticon {
    color: black !important;
  }
  .ant-upload-list-item-name {
    color: #069 !important;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .anticon-delete {
    color: black !important;
  }
  .ant-upload-select {
    width: 100%;
    border-radius: 8px;
    background: var(--Blue, #069);
    height: 48px;
    padding: 14px 38px 13px 38px;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    line-height: normal;
  }

  .ant-select-selector {
    width: 500px !important;
    height: 50px !important;
  }

  .ant-form-item .ant-form-item-label > label {
    color: #777e91;
  }
  .ant-select-single {
    height: 50px;
  }
  .ant-select.ant-select-in-form-item {
    width: unset;
  }
`;

export const CommonButton = styled(Button)`
  border-radius: 8px;
  background-color: ${(props) => (props.primary ? "#000;" : "#FFF")};
  color: ${(props) => props.color || "white"} !important;
  display: flex;
  border: ${(props) => (props.bordered ? "1px solid #069" : "none")};
  font-size: ${(props) => props.fontSize || "16px"};
  align-items: center;
  box-shadow: none;
  font-weight: 600;
  outline: none;
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  justify-content: center;
  padding: 7px 0px 7px 0px;

  &:hover {
    background: ${(props) => (props.primary ? "#000" : "#FFF")} !important;
    color: ${(props) => props.color || "white"} !important;
    border: ${(props) =>
      props.bordered ? "1px solid #069" : "none"} !important;
  }
  span {
    color: ${(props) => props.color || "white"} !important;
  }
`;
