import { useDispatch, useSelector } from "react-redux";
import {
  useGetInstituteWiseDataQuery,
  useGetTemplateDataQuery,
} from "../service/templateUpdateApi";
import { useEffect } from "react";
import { resetTextAreas, setTextAreas } from "../store/textEditorSlice";
import { resetLines, setLines } from "../store/lineSlice";
import {
  addImages,
  resetImages,
  updateImageCoordinates,
} from "../store/ImageSlice";
import { resetBgImage, setBgImage } from "../store/BgSlice";
import {
  setTemplateDimensions,
  setTemplateId,
} from "../store/templateDimesnionSlice";
import { setTemplateName, setTemplateNameOnChange } from "../service/auth";

const useTemplateData = (selectedTemplateId, currentTab) => {
  const { token } = useSelector((state) => state?.auth);
  const templateDimension = useSelector((state) => state.templateDimension);
  console.log("templateDimension", templateDimension);

  console.log(
    "currentTab",
    typeof currentTab,
    currentTab === 1,
    currentTab,
    selectedTemplateId
  );
  const dispatch = useDispatch();

  // Fetch template data
  const { data: templateData, isLoading } = useGetTemplateDataQuery(
    {
      _id: selectedTemplateId ?? templateDimension?.templateId,
    },
    {
      skip:
        (!selectedTemplateId && !templateDimension?.templateId) ||
        currentTab === 2 ||
        !token,
      forceRefetch: true,

      refetchOnMountOrArgChange: true,
    }
  );
  const { data: drafttemplateData, isLoading: draftLoader } =
    useGetInstituteWiseDataQuery(
      {
        _id: selectedTemplateId ?? templateDimension?.templateId,
      },
      {
        skip:
          (!selectedTemplateId && !templateDimension?.templateId) ||
          currentTab === 1 ||
          !token,
        forceRefetch: true,
        refetchOnMountOrArgChange: true,
      }
    );

  console.log("selectedTemplateId_______>>>>>>", selectedTemplateId);
  console.log(
    "templateData",
    templateData,
    drafttemplateData,
    templateData?.data[0]?.templateJson[4]
  );
  useEffect(() => {
    dispatch(setTemplateId(selectedTemplateId));
    console.log("works", selectedTemplateId);
  }, [dispatch, selectedTemplateId]);

  // Populate Redux store when templateData changes
  useEffect(() => {
    const data = currentTab === 1 ? templateData : drafttemplateData;
    console.log("data", data);
    if (data) {
      console.log("data from useTemplate", data);
      const templateJson = data?.data[0]?.templateJson;

      if (data?.data[0]?.name) {
        dispatch(setTemplateNameOnChange(data?.data[0]?.name));
      }

      // Add lines if they exist
      if (templateJson?.[0]?.lines) {
        dispatch(setLines(templateJson[0].lines));
      }

      // Add text areas if they exist
      if (templateJson?.[1]?.textAreas) {
        dispatch(setTextAreas(templateJson?.[1]?.textAreas));
      }

      // Add images if they exist+
      if (templateJson?.[2]?.images) {
        dispatch(addImages(templateJson[2].images));
        templateJson[2].images.forEach((image) => {
          dispatch(
            updateImageCoordinates({
              id: image.id,
              x: image?.x || 0,
              y: image?.y || 0,
            })
          );
        });
      }

      // Set background image if it exists
      if (templateJson?.[3]) {
        dispatch(setBgImage({ url: templateJson[3].url }));
      }

      if (templateJson?.[4]) {
        console.log("templateJson[4]", templateJson[4]);
        dispatch(
          setTemplateDimensions({ 
            width: templateJson[4]?.width,
            height: templateJson[4]?.height,
          })
        );
      }
    }
  }, [templateData, dispatch, currentTab, drafttemplateData]);

  // Function to reset state
  const resetState = () => {
    // localStorage.removeItem("templateId");
    dispatch(resetImages());
    dispatch(resetTextAreas());
    dispatch(resetLines());
    dispatch(resetBgImage());
  };

  return { isLoading, draftLoader, resetState };
};

export default useTemplateData;
