// ImageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  images: [],
  selectedId: null,
};

const uploadSlice = createSlice({
  name: "uploads",
  initialState,
  reducers: {
    setDefaultImages: (state, action) => {
      state.images = action.payload; // Set the images fetched
    },
    setImageURL: (state, action) => {
      const { id, file, url } = action.payload;
      const image = state.images.find((img) => img.id === id);
      if (image) {
        image.file = file;
        image.url = url;
      }
    },
    // Other reducers remain the same
    addImages: (state, action) => {
      state.images = [...state.images, ...action.payload];
    },
    updateImageCoordinates: (state, action) => {
      const { id, x, y } = action.payload;
      const image = state.images.find((img) => img.id === id);
      if (image) {
        image.x = x;
        image.y = y;
      }
    },
    updateImageDimensions: (state, action) => {
      const { id, width, height } = action.payload;
      const image = state.images.find((img) => img.id === id);
      if (image) {
        image.width = width;
        image.height = height;
      }
    },
    removeImage: (state, action) => {
      state.images = state.images.filter((img) => img.id !== action.payload);
    },
    setSelectId: (state, action) => {
      state.selectedId = action.payload;
    },
    resetImages: (state) => {
      state.images = initialState.images; // Reset to initial images
    },
    replaceImage: (state, action) => {
      const { id, url } = action.payload;
      const image = state.images.find((img) => img.id === id);

      if (image && image.elementReadOnly) {
        image.url = url; // Replace the image URL
      }
    },
  },
});

export const {
  setDefaultImages,
  setImageURL,
  addImages,
  removeImage,
  updateImageCoordinates,
  updateImageDimensions,
  setSelectId,
  resetImages,
  replaceImage,
} = uploadSlice.actions;
export default uploadSlice.reducer;
