import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

// Common attributes for text areas

const defaultAttributes = {
  fontFamily: "Arial, sans-serif",
  fontSize: "16px",
  isBold: false,
  isItalic: false,
  isUnderline: false,
  fontColor: "#000000",
  textAlign: "center",
  width: 250,
  height: 40,
  position: { x: 0, y: 0 },
};

// Predefined default text areas with unique IDs and content
const defaultTextAreas = [
  {
    id: "default-1",
    content: "[recipient.firstName]",
    fontSize: "28px",
    position: { x: 200, y: 200 },
    elementReadOnly: true,
    width: "auto",
  },
  {
    id: "default-2",
    content: "[recipient.date]",
    fontSize: "26px",
    position: { x: 62, y: 473 },
    elementReadOnly: true,
    width: "auto",
  },
  {
    id: "default-lastName",
    content: "[recipient.lastName]",
    position:  { x: 450, y: 200 },
    fontSize: "28px",


    // defaultField: true,
    // enabled: false,
    elementReadOnly: true,

    width: "auto",
  },
  {
    id: "default-email",
    content: "[recipient.email]",
    position: { x: 334, y: 295 },
    defaultField: true,
    enabled: false,
    width: "auto",
  },
  {
    id: "default-courseName",
    content: "[recipient.courseName]",
    position: { x: 334, y: 295 },

    defaultField: true,
    enabled: false,
    width: "auto",
  },
  {
    id: "default-description",
    content: "[recipient.description]",
    position: { x: 334, y: 295 },

    defaultField: true,
    enabled: false,
    width: "auto",
  },
  {
    id: "default-walletAddress",
    content: "[recipient.address]",
    position: { x: 334, y: 295 },

    defaultField: true,
    enabled: false,
    width: "auto",
  },
].map((textArea) => ({ ...defaultAttributes, ...textArea })); // Combine defaults

const initialState = {
  textAreas: defaultTextAreas,
  selectedIndex: null,
  selectedId: null,
};

const textEditorSlice = createSlice({
  name: "textEditor",
  initialState,
  reducers: {
    setTextAreas: (state, action) => {
      state.textAreas = action.payload;
    },
    addTextArea: (state, action) => {
      console.log("+++++++++++++++action", action);
      const payload = action?.payload;
      state.textAreas.push({
        id: payload?.id || uuidv4(),
        content: payload?.content || "Sample",
        fontFamily: payload?.fontFamily || "Arial, sans-serif",
        fontSize: payload?.fontSize || "16px",
        isBold: payload?.isBold || false,
        isItalic: payload?.isItalic || false,
        isUnderline: payload?.isUnderline || false,
        fontColor: payload?.fontColor || "#000000",
        textAlign: payload?.textAlign || "left",
        position: {
          x: payload?.position?.x || 300,
          y: payload?.position?.y || 100,
        },
        width: payload?.width || 70,
        height: payload?.height || 0,
        elementReadOnly: payload?.elementReadOnly || false,
        customField: payload?.customField || false,
        enabled: payload?.enabled || false,
      });
    },
    removeTextArea: (state, action) => {
      const textAreaId = action.payload;
      if (!textAreaId?.startsWith("default")) {
        state.textAreas = state.textAreas.filter(
          (textArea) => textArea.id !== textAreaId
        );
      }
    },
    setContent: (state, action) => {
      const { id, content } = action.payload;
      if (!id?.startsWith("default-1")) {
        const textArea = state.textAreas.find((ta) => ta.id === id);
        if (textArea) {
          textArea.content = content;
        }
      }
    },
    setStyle: (state, action) => {
      const { id, key, value } = action.payload;
      const textArea = state.textAreas.find((ta) => ta.id === id);
      if (textArea) {
        textArea[key] = value;
      }
    },
    setPosition: (state, action) => {
      const { id, x, y } = action.payload;
      const textArea = state.textAreas.find((ta) => ta.id === id);
      if (textArea) {
        textArea.position = { x, y };
      }
    },
    setDimension: (state, action) => {
      const { id, width } = action.payload;
      const textArea = state.textAreas.find((ta) => ta.id === id);
      if (textArea) {
        textArea.width = width;
      }
    },
    selectTextArea: (state, action) => {
      console.log("action?.payloadID", action?.payload);
      state.selectedIndex = action.payload.index;
      state.selectedId = action.payload.id;
    },
    toggleStyle: (state, action) => {
      const { id, key } = action.payload;
      const textArea = state.textAreas.find((ta) => ta.id === id);
      if (textArea) {
        textArea[key] = !textArea[key];
      }
    },
    toggleDefaultFieldEnabled: (state, action) => {
      console.log("action.payload", action.payload);
      const { id, value } = action.payload;

      const textArea = state.textAreas.find(
        (ta) => ta.id === id && ta.defaultField
      );
      console.log("textArea from redux", id, value, textArea);
      if (textArea) {
        textArea.enabled = value;
      }
    },
    toggleCustomFeildEnabled: (state, action) => {
      console.log("action.payload", action.payload);
      const { id, value } = action.payload;

      const textArea = state.textAreas.find(
        (ta) => ta.id === id && ta.customField
      );
      console.log("textArea from redux", id, value, textArea);
      if (textArea) {
        textArea.enabled = value;
      }
    },

    resetTextAreas: (state) => {
      state.textAreas = defaultTextAreas;
      state.selectedIndex = null;
      state.selectedId = null;
    },
  },
});

export const {
  addTextArea,
  removeTextArea,
  setContent,
  setStyle,
  setPosition,
  selectTextArea,
  toggleStyle,
  setDimension,
  resetTextAreas,
  setTextAreas,
  toggleDefaultFieldEnabled,
  toggleCustomFeildEnabled,
} = textEditorSlice.actions;

export default textEditorSlice.reducer;
