// store/lineSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  lines: [], // Array to hold multiple lines
  // lines: [
  //   {
  //     id: 0,
  //     position: {
  //       x: 54.54542541503906,
  //       y: 309.99996185302734,
  //     },
  //     width: 579.9858093261719,
  //     height: 2,
  //     color: "black",
  //   },
  //   {
  //     id: 1,
  //     position: {
  //       x: 54.54542541503906,
  //       y: 349.99996185302734,
  //     },
  //     width: 579.9858093261719,
  //     height: 2,
  //     color: "black",
  //   },
  // ], // Array to hold multiple lines
  selectedId: null,
};

const draggableLineSlice = createSlice({
  name: "draggableLine",
  initialState,
  reducers: {
    setLines: (state, action) => {
      const payload = action?.payload;
      state.lines = payload;
    },
    addLine: (state, action) => {
      const payload = action?.payload;
      state.lines.push({
        id: payload?.id || uuidv4(),
        position: {
          x: payload?.position?.x || 150,
          y: payload?.position?.y || 200,
        },
        width: payload?.width || 100,
        height: payload?.height || 2,
        color: payload?.color || "black",
        dotted: payload?.dotted || false, // Add dotted property based on payload
      });
    },
    
    setLinePosition: (state, action) => {
      const { id, x, y } = action.payload;
      const line = state.lines.find((line) => line.id === id);
      if (line) {
        line.position.x = x;
        line.position.y = y;
      }
    },
    setSelectedLineId: (state, action) => {
      state.selectedId = action.payload;
    },
    setLineWidth: (state, action) => {
      const { id, width } = action.payload;
      const line = state.lines.find((line) => line.id === id);
      if (line) {
        line.width = width;
      }
    },
    setLineColor: (state, action) => {
      const { id, color } = action.payload;
      const line = state.lines.find((line) => line.id === id);
      if (line) {
        line.color = color;
      }
    },
    removeLine: (state, action) => {
      state.lines = state.lines.filter((line) => line.id !== action.payload.id);
    },
    resetLines: (state) => {
      state.lines = [];
    },
  },
});

export const {
  addLine,
  setLinePosition,
  setLineWidth,
  setLineColor,
  removeLine,
  resetLines,
  setSelectedLineId,
  setLines,
} = draggableLineSlice.actions;
export default draggableLineSlice.reducer;
