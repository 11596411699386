import React, { useCallback, useState } from "react";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import OTPInput from "react-otp-input";
import { Button, Form } from "antd";
import { CommonButton } from "../../../../style/globalStyle";
import {
  setIsLoggedIn,
  setToken,
  setUserDetails,
  setUserId,
  useValidateOtpMutation,
} from "../../../../service/auth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../routes/routeConstants";

const UserOtpModal = ({ userId, otpData, setIsOtpLoader }) => {
  const [isModalVisible, setIsModalVisible] = useState(userId ? true : false);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [validateOtp] = useValidateOtpMutation();
  const dispatch = useDispatch();
  console.log("otpData", otpData);

  const verifyOtp = useCallback(
    async (otp) => {
      setIsOtpLoader(true);
      try {
        const payloadData = {
          uuid: otpData,
          otp: Number(otp),
          userId,
        };
        const response = await validateOtp({
          payloadData,
        });
        if (response?.data?.success) {
          console.log("response.data", response.data);
          toast.success("Otp verified succesully!");
          localStorage.setItem("token", response?.data?.token);
          localStorage.setItem("isLoggedIn", true);

          dispatch(setToken(response?.data?.token));

          dispatch(setUserDetails(response?.data?.data));
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response?.data?.data)
          );
          dispatch(setIsLoggedIn(true));
          setIsOtpLoader(false);
          navigate(ROUTES.HOME);
          window.location.reload();
          setIsModalVisible(false);
        } else if (response?.error) {
          toast.error(response?.error?.data?.message);
          setIsOtpLoader(false);
        }
      } catch (error) {
        console.log("error", error);
        setIsOtpLoader(false);
      }
    },
    [dispatch, navigate, otpData, setIsOtpLoader, userId, validateOtp]
  );

  const [otp, setOtp] = useState("");
  const handleClose = () => {
    console.log("clicked");
    setIsModalVisible(false);
  };
  const handleFinish = (values) => {
    console.log("values", values);

    const { otp } = values;
    if (!otp || otp.length !== 6) {
      return form.setFields([
        {
          name: "otp",
          errors: ["Please enter a valid 6-digit OTP."],
        },
      ]);
    }
    verifyOtp(otp);
  };

  return (
    <div>
      <div>
        <CustomModal
          visible={isModalVisible}
          onClose={handleClose}
          title="Verify OTP"
          width={600}
          closable={false}
          maskClosable={false} // Prevents closing on outside click
        >
          <div className="sub-heading">
            <span>Please verify the OTP to proceed.</span>
            <Form form={form} onFinish={handleFinish}>
              <Form.Item
                name="otp"
                className="center-error-message"
                rules={[
                  { required: true, message: "Please enter valid OTP !" },
                ]}
              >
                <OTPInput
                  value={otp}
                  inputType="tel"
                  containerStyle={{
                    gap: "15px",
                    marginTop: "25px",
                    justifyContent: "center",
                  }}
                  inputStyle={{
                    width: "2em",
                    height: "2em",
                    border: "2px solid black",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                />
              </Form.Item>
              <Form.Item
                style={{
                  marginTop: "40px",
                  marginBottom: "40px",
                }}
              >
                <CommonButton
                  htmlType="submit"
                  fontSize="18px"
                  primary
                  color="#FFF"
                  width="100%"
                >
                  Submit
                </CommonButton>
              </Form.Item>
            </Form>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};
export default React.memo(UserOtpModal);
