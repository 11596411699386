import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import DraftTableList from "./components/DraftTableList";
import UserTemplateList from "./components/UserTemplateList.";

export const TemplatesTab = () => {
  return (
    <>
      <Tabs>
        <TabPane tab="Draft Templates" key="1">
          <DraftTableList />
        </TabPane>
        <TabPane tab="My Templates" key="2">
          <UserTemplateList />
        </TabPane>
      </Tabs>
    </>
  );
};
