import styled from "styled-components";

export const StyledTemplateWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  /* height: 100vh; */
  .cert-handle {
    width: 100%;

    height: 100%;
    /* display: flex;  */
    /* justify-content: center;
    position:relative;

    /* align-items: center; */
    overflow: auto; /* Enable scrolling when the content exceeds the container */
  }

  .preview-section {
    width: 70%;
    border: 2px solid gray;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    overflow: scroll;
  }

  .toolbar-section {
    width: 30%;
    border: 1px solid green;
  }

  textarea {
    background: transparent;
  }

  .no-pointer-events {
    pointer-events: none;
  }

  .draggable-line {
    user-select: none;
  }

  .zoom-level-container {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    margin-left: auto;
    margin-right: 10px;
    top: -25px;
    .zoom-input {
      span {
        font-size: 20px;
        color: #060606;
      }
    }
  }

  .drawer-btn {
    position: absolute;
    top: 225px;
    z-index: 1;
    .ant-btn-primary {
      font-size: 18px;
      padding: 10px;
      border-radius: 0% 25% 25% 0% / 25% 25% 25% 25%;
    }
  }
  .tabs-drawer {
    .ant-drawer .ant-drawer-body {
      padding: 0px !important;
    }
  }
  @media (max-width: 1182px) {
    gap: 0px;

    .preview-section {
      width: 100%;
    }
    .tool-bar-container {
      display: none;
    }
  }
  @media (min-width: 1182px) {
    .drawer-btn {
      display: none;
    }
  }
`;

export const StyledCertificateContainer = styled.div`
  border: 1px solid blue;
  overflow: hidden;
  background-image: ${(props) =>
    props.bgImageUrl ? `url("${props.bgImageUrl}")` : ""};
  background-size: 100% 100%; /* Ensures the image covers the whole div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Avoids image repetition */
  width: ${({ width }) => `${width}px`}; // Set dynamic width
  aspect-ratio: ${({ height, width }) => width / height}; // Set dynamic height

  position: relative;

`;
