import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  showTemplateScreen: false,
  activeTab: "1",
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    setShowTemplateScreen: (state, action) => {
      state.showTemplateScreen = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setLoading, resetLoading, setShowTemplateScreen, setActiveTab } =
  loadingSlice.actions;
export default loadingSlice.reducer;
