import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetDraftTemplateDataQuery } from "../../../../../service/templateUpdateApi";
import { useNavigate } from "react-router-dom";
import useTemplateData from "../../../../../hooks/setTemplateData";
import { setActiveTab } from "../../../../../store/loadingSlice";
import { subString } from "../../../../../helper/substring";
import moment from "moment";
import { Button, Pagination, InputNumber, Image, Tooltip } from "antd";
import { DraftTemplateTableStyled } from "../../../style";
import { CustomTableStyled } from "../../../../../components/CustomTable/style";
import CustomTable from "../../../../../components/CustomTable/CustomTable";
import Spinner from "../../../../../components/spinner/Spinner";
import { EyeOutlined } from "@ant-design/icons";
import { setTemplateId } from "../../../../../store/templateDimesnionSlice";
import { skipToken } from "@reduxjs/toolkit/query";

const DraftTemplateTableList = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const skip = (page - 1) * limit;
  const dispatch = useDispatch();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [isTemplateReady, setTemplateReady] = useState(false);
  const [visible, setVisible] = useState(false);
  const [scaleStep, setScaleStep] = useState(0.5);
  const [previewUrl, setPreviewUrl] = useState("");
  const authToken = useSelector((state) => state.auth.token);
  const [templateUrlTooltip, setTemplateUrlTooltip] = useState([]);
  // Array(data?.data?.length).fill(false)

  const { data: draftedData, isLoading: drafteLoader } =
    useGetDraftTemplateDataQuery(
      authToken ? { limit, skip, isDraft: true } : skipToken
    );
  const navigate = useNavigate();
  const { isLoading, resetState } = useTemplateData(selectedTemplateId, 2);

  useEffect(() => {
    if (isTemplateReady && !isLoading) {
      navigate("/editor", {
        state: { from: "editDraft", id: selectedTemplateId },
      });
      setTemplateReady(false);
    }
  }, [isTemplateReady, isLoading, navigate, selectedTemplateId]);

  const updatedData = draftedData?.data?.map((item) => ({
    ...item,
    fileName: "Certificate_11222",
    ownerName: "Simson Joe",
  }));
  const templateDimension = useSelector((state) => state.templateDimension);
  console.log("fitemplateDimensionrst", templateDimension);

  const handleTemplateSelect = useCallback(
    (id) => {
      console.log("id-------->", id, templateDimension?.templateId);

      resetState();

      setSelectedTemplateId(id);
      dispatch(setTemplateId(id));
      localStorage.setItem("templateId", id);
      setTemplateReady(true);
      dispatch(setActiveTab("1"));
    },
    [dispatch, resetState, templateDimension?.templateId]
  );

  const handlePreviewClick = (url) => {
    setPreviewUrl(url);
    setVisible(true);
  };
  const copyCode = useCallback((record, index, fn) => {
    // Fallback for copying text to clipboard
    const fallbackCopyTextToClipboard = (text) => {
      const textArea = document.createElement('textarea');
      textArea.value = text;
  
      // Prevent scrolling to bottom of the page
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.opacity = '0';
  
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
  
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
  
      document.body.removeChild(textArea);
    };
  
    try {
      // Attempt to use Clipboard API first
      navigator?.clipboard?.writeText(record).catch((err) => {
        console.error('Clipboard API failed, falling back to execCommand:', err);
        fallbackCopyTextToClipboard(record);
      });
    } catch (err) {
      // If Clipboard API is unavailable, use fallback
      fallbackCopyTextToClipboard(record);
    }
  
    // Handle Tooltip State
    fn((prev) => {
      const newTooltipState = [...prev];
      newTooltipState[index] = true;
      setTimeout(() => {
        newTooltipState[index] = false;
        fn([...newTooltipState]);
      }, 1000);
      return newTooltipState;
    });
  }, []);
  const columns = useMemo(
    () => [
      {
        title: (
          <>
            <div style={{ whiteSpace: "nowrap" }}>S.no</div>
          </>
        ),
        dataIndex: "Sno",
        key: "Sno",
        render: (item, index, key) => {
          const serialNumber = skip + key + 1;
          return (
            <div style={{ textAlign: "start", marginLeft: "15px" }}>
              {serialNumber}
            </div>
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Template name</div>,
        dataIndex: "name",
        key: "Name",
        render: (item) => {
          const formattedUrl = item ? item : "NA";
          return <span style={{ wordBreak: "break-all" }}>{formattedUrl}</span>;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Template URL</div>,
        dataIndex: "templateUrl",
        key: "templateUrl",
        render: (item, key, index) => {
          const formattedUrl = item ? subString(item) : "NA";
          return (
            <>
              <div className="wallet-card">
                <span style={{ wordBreak: "break-all" }}>{formattedUrl}</span>

                <Tooltip
                  getPopupContainer={(trigger) => trigger.parentNode}
                  title={"Copied"}
                  placement="topRight"
                  open={templateUrlTooltip[index]}
                  visible={false}
                  color="#292956"
                >
                  <img
                    onClick={() => copyCode(item, index, setTemplateUrlTooltip)}
                    src="/images/copy.svg"
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              </div>
            </>
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Last updated at </div>,
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (item) => {
          return <div>{moment(item).format("MM-DD-YYYY")}</div>;
        },
      },
      {
        title: (
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            Action
          </div>
        ),
        key: "action",
        render: (item, record) => (
          <Button onClick={() => handleTemplateSelect(record?._id)}>
            Edit Template
          </Button>
        ),
      },
      {
        title: <div style={{ textAlign: "end" }}>Preview</div>,
        dataIndex: "templateUrl",
        key: "templateUrl",
        render: (url) => (
          <div style={{ marginRight: "30px" }}>
            <EyeOutlined onClick={() => handlePreviewClick(url)} />
          </div>
        ),
      },
    ],
    [copyCode, handleTemplateSelect, skip, templateUrlTooltip]
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <>
      <DraftTemplateTableStyled>
        <CustomTableStyled>
          <div className="table-container">
            <CustomTable
              data={updatedData}
              columns={columns}
              isLoading={drafteLoader}
            />
          </div>
        </CustomTableStyled>
        <Pagination
          total={draftedData?.total}
          pageSize={limit}
          current={page}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </DraftTemplateTableStyled>

      <Image
        width={200}
        style={{ display: "none" }}
        src={previewUrl}
        preview={{
          visible,
          scaleStep,
          src: previewUrl,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
      {(isTemplateReady || isLoading || drafteLoader) && <Spinner />}
    </>
  );
};

export default React.memo(DraftTemplateTableList);
