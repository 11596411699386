import styled from "styled-components";

export const TextEditorStyled = styled.div`
  margin-bottom: 45px;
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #f4f4f4; */
  /* border-radius: inherit; */
  padding: 10px 10px;
  border-bottom: 2px solid #e8e8e8;
  background: #dfdfdf;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  overflow-y: hidden;

  height: 100px;

  .color-picker {
    width: 40px;
    height: 32px;
    background: white;
    border: 2px solid gray;
    border-radius: 4px;
  }

  .style-btn {
    display: flex;
    align-items: center;
    gap: inherit;
  }

  .save-btn {
    display: flex;
    align-items: center;
    gap: 20px;
    /* border: 2px solid #ebe1e1;
    background: #f4f4f4;
    border-radius: 10px; */
    padding: 10px;
    .ant-btn-default {
      height: 46px;
      border-radius: 10px;
      border: 2px solid gray;
    }
    .ant-btn-primary {
      height: 46px;
      border-radius: 10px;
    }
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideOut {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(-50px);
    }
  }

  .font-size-slider {
    /* position: absolute;
    top: 102px;
    left: 662px; */
    background-color: #ffffff;
    /* border: 1px solid #ddd; */
    border-radius: 10px;
    /* padding: 10px; */
    width: 200px;
    opacity: 0;
    transform: translateY(-50px);
    visibility: hidden;
    transition: visibility 0.3s ease;
    /* z-index: 999; */
  }

  .font-size-slider.show {
    opacity: 1;
    visibility: visible;
    animation: slideIn 0.3s forwards;
  }

  .font-size-slider.hide {
    opacity: 0;
    animation: slideOut 0.3s forwards;
    visibility: hidden;
  }

  .bold-btn {
    font-size: 24px;
    font-weight: bold;
  }
  .Italic-btn {
    font-size: 24px;
    font-weight: bold;
    font-style: italic;
  }
  .underline-btn {
    font-size: 24px;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const Toolbar = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  padding: 10px;
  /* padding: 10px;
  border: 2px solid #ebe1e1;
  background: #f4f4f4;
  border-radius: 10px; */
  background: rgba(255, 255, 255, 0.3);
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(10.5px);
  /* -webkit-backdrop-filter: blur( 10.5px ); */
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  .ant-radio-group {
    display: flex;
  }
`;
