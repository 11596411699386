import * as React from "react";
const ElementIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="currentColor"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M7.564 40h10c1.375 0 2.5-1.126 2.5-2.502v-10.01a2.509 2.509 0 0 0-2.5-2.501h-10a2.508 2.508 0 0 0-2.5 2.502v10.009A2.508 2.508 0 0 0 7.564 40ZM41 26.238a2.508 2.508 0 0 0-2.5-2.502H25.686a2.508 2.508 0 0 0-2.5 2.502v5.63a2.508 2.508 0 0 0 2.5 2.502H38.5c1.375 0 2.5-1.126 2.5-2.502v-5.63ZM17.914 13.729 10.808.903C10.474.3 10.035 0 9.595 0c-.44 0-.879.3-1.212.903L1.277 13.729c-.667 1.203-.088 2.188 1.287 2.188h14.062c1.375 0 1.954-.985 1.288-2.188Zm-12.85-.627a.313.313 0 1 1 0-.626.313.313 0 0 1 0 .626ZM9.713 5.12l-3.438 6.255a.312.312 0 1 1-.547-.301l3.437-6.256a.312.312 0 1 1 .548.302Z" />
      <path d="M20.218 4.063c-1.98 0-3.807.646-5.286 1.738l4.073 7.344c.538.97.576 1.985.106 2.785-.471.799-1.377 1.258-2.487 1.258h-4.251a8.906 8.906 0 0 0 16.751-4.219 8.906 8.906 0 0 0-8.906-8.906ZM31.625 7.5h4.063v4.063a.312.312 0 1 0 .624 0V7.5h4.063a.313.313 0 0 0 0-.625h-4.063V2.812a.312.312 0 1 0-.624 0v4.063h-4.063a.313.313 0 0 0 0 .625Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h40v40H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default ElementIcon;
