import { useDispatch, useSelector } from "react-redux";
import {
  selectTextArea,
  toggleCustomFeildEnabled,
} from "../../../store/textEditorSlice";
import replaceDivsWithNewlines from "../../../helper/contentString";
import { Switch, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getDynamicMessage } from "../../../constant/messages";

const CustomAttributes = () => {
  const dispatch = useDispatch();
  const { textAreas } = useSelector((state) => state.textEditor);

  const textWithCustomAttribute = textAreas?.filter(
    (item) => item?.customField
  );

  const handleSwitchChange = (value, id) => {
    dispatch(toggleCustomFeildEnabled({ id, value }));
    dispatch(selectTextArea({ id: id }));
  };

  return (
    <div>
      {textWithCustomAttribute?.length > 0 && (
        <h3>
          Custom Attributes{" "}
          <span>
            <Tooltip
              placement="bottom"
              title={getDynamicMessage("Custom Attribute")}
            >
              <InfoCircleOutlined color="black" width={40} height={40} />
            </Tooltip>
          </span>
        </h3>
      )}

      <div className="text-queue-container">
        {textAreas
          ?.filter((item) => item?.customField) // Filter to include only items with customFeild set to true
          .map((item, index) => (
            <div
              className="text-queue"
              key={item.id}
              style={{
                backgroundColor: "#f4f4f4",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() =>
                dispatch(selectTextArea({ index: index, id: item?.id }))
              }
            >
              <span style={{ fontFamily: `${item.fontFamily} ` }}>
                {replaceDivsWithNewlines(item.content)}
              </span>
              <Switch
                onChange={(value) => handleSwitchChange(value, item?.id)}
                checked={item?.enabled}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CustomAttributes;
