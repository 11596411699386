import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import HomePage from "./pages/Home";
import Header from "./components/Header/Header";
import { TemplateLayout } from "./components/Layout/Layout";
import Auth from "./routes/auth";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import DefaultImagesLoader from "./components/Dragable/components/DefaultImagesUrl";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "./components/spinner/Spinner";
import { setUserDetails, useGetUsersDetailsQuery } from "./service/auth";

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading.loading);
  const userDetails = useSelector((state) => state?.auth?.userDetails);

  const { data, error, isLoading: userLoader } = useGetUsersDetailsQuery();
  console.log("data user", data);


  useEffect(() => {
    if (data?.data) {
      const updatedData = {
        ...data?.data,
        templateName: userDetails?.templateName || "", // Use a fallback if templateName is undefined
      };
  
      dispatch(setUserDetails(updatedData));
      localStorage.setItem("userDetails", JSON.stringify(updatedData));
    }
  }, [data?.data, userDetails?.templateName, dispatch]);

  return (
    <>
      {isLoading && <Spinner />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{
          zIndex: 20000,
        }}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
      />
      {/* For Images that are default this is a  loader to make a file as blob to be used as URL */}

      <Router>
        <DefaultImagesLoader />
        <Header />
        <div style={{ marginTop: 20 }}>
          <Routes>
            <Route path="/" element={<HomePage />} />

            <Route path="" element={<Auth />}>
              <Route path="/editor" element={<TemplateLayout />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </>
  );
};

export default App;
