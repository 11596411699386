import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDefaultImages } from "../../../store/ImageSlice"; // Action to set default images
import { useLocation } from "react-router-dom";

const fallbackImages = {
  logo: "/images/logo (1).png",
  signature: "/images/signature.png",
};

const fetchDefaultImages = async (
  userDetails,
  containerWidth,
  containerHeight
) => {
  console.log("containerWidth", containerWidth);
  console.log("userDetails from fetch Default", userDetails);

  const defaultImages = [
    { id: "default-logo", path: userDetails?.profileLogo || fallbackImages.logo },
    {
      id: "default-signature",
      path: userDetails?.signature || fallbackImages.signature,
    },
  ];

  const imageWidth = 133.75;
  const imageHeight = 136.25;

  const imagePromises = defaultImages.map(async (img) => {
    if (!img.path) return null;

    try {
      const response = await fetch(img.path);
      console.log("response", response);
      if (!response.ok) throw new Error(`Failed to fetch ${img.path}`);

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const backgroundFile = new File([blob], `${img.id}.png`, {
        type: "image/png",
      });

      // Calculate positions based on top-left or bottom-right
      const x = img.id === "default-logo" ? 0 : containerWidth - imageWidth;
      const y = img.id === "default-logo" ? 0 : containerHeight - imageHeight;

      return {
        id: img.id,
        file: backgroundFile,
        url: url,
        width: imageWidth,
        height: imageHeight,
        x: Math.max(x, 0), // Ensure x and y are not negative
        y: Math.max(y, 0),
        elementReadOnly: true,
        defaultPath: img.path,
      };
    } catch (error) {
      console.error(`Failed to load image ${img.path}:`, error);
      const fallbackPath = fallbackImages[img.id.split("-")[1]];

      const x = img.id === "default-logo" ? 0 : containerWidth - imageWidth;
      const y = img.id === "default-logo" ? 0 : containerHeight - imageHeight;

      return {
        id: img.id,
        file: null,
        url: fallbackPath,
        width: imageWidth,
        height: imageHeight,
        x: Math.max(x, 0),
        y: Math.max(y, 0),
        elementReadOnly: true,
        defaultPath: fallbackPath,
      };
    }
  });

  return Promise.all(imagePromises);
};

const DefaultImagesLoader = () => {
  const location = useLocation();
  const zoomLevel = useSelector((state) => state.bgUploads.zoomLevel);
  const dispatch = useDispatch();
  const templateDimension = useSelector((state) => state.templateDimension);
  console.log("templateDimension", templateDimension);
  const images = useSelector((state) => state.uploads.images);
  console.log("images loader", images);
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  console.log("userDetails from image loader", userDetails);

  useEffect(() => {
    const loadImages = async () => {
      console.log(
        "templateDimension?.templateId",
        templateDimension?.templateId
      );
      if (!templateDimension?.templateId) {
        console.log("into loadImages");
        const images = await fetchDefaultImages(
          userDetails,
          templateDimension.width,
          templateDimension.height
        );
        console.log("images loader2", images);
        const validImages = images.filter((img) => img !== null);
        console.log("validImages", validImages);
        dispatch(setDefaultImages(validImages));
      }
    };

    loadImages();
  }, [
    dispatch,
    templateDimension?.templateId,
    templateDimension.width,
    templateDimension.height,
    location?.pathname,
    userDetails,
  ]);

  return null;
};

export default DefaultImagesLoader;
