import React from "react";
import { AddLines } from "../AddLines/AddLines";
import ImageToolBar from "../ImageUpload";

const ElementsTab = () => {
  return (
    <div className="element-tab">
      <div className="image-tool-bar">
        <ImageToolBar />
      </div>
      <div className="add-line-tool">
        <AddLines />
      </div>
    </div>
  );
};

export default ElementsTab;
