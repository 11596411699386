import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  x: 84.54541015625,
  y: 85,
};

const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    setPosition: (state, action) => {
      state.x = action.payload.x;
      state.y = action.payload.y;
    },
  },
});

export const { setPosition } = positionSlice.actions;
export default positionSlice.reducer;
