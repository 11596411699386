import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { ROUTES } from "./routeConstants";

export default function Auth() {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const userDetails = useSelector((state) => state?.auth?.userDetails);

  console.log("isLoggedIn", isLoggedIn);
  
  
  if (isLoggedIn )  {
    return <Outlet />;
  } else {
    return (
      <Routes>
        <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
      </Routes>
    );
  }
}
