import styled from "styled-components";

export const ToolBarTabsStyled = styled.div`
  .tab-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
    background: #dfe1e6;
    transition: all 0.3s;
  }
  .ant-tabs-content-holder {
    width: 370px;
    padding: 10px;
  }
  .ant-tabs-tab {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .element-tab {
    height: 100vh;
    .image-tool-bar {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding-bottom: 20px;
    }

    .add-line-tool {
      border-top: 2px solid gray;
      padding-top: 10px;
      margin-top: 20px;
    }
  }

  .text-queue-container {
    /* height: 80vh; */
    overflow-y: auto;
  }
  .text-queue {
    margin-top: 10px;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    transition: all 0.3s;
    overflow: auto;
    .ant-ribbon-text {
      font-size: 14px;
    }
    span {
      font-size: 20px;
    }
    &:hover {
      background: #f4f4f4;
      color: black;
    }
  }
  .add-text-btn {
    margin-bottom: 20px;
    .ant-btn-default {
      width: 100%;
      font-size: 18px;
    }
  }

  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 0;
  }

  @media (max-width: 910px) {
    .ant-tabs-content-holder {
      max-width: 370px;
    }
  }
`;
