import { useEffect, useRef } from "react";

export const ContentEditable = ({
  textArea,
  html,
  onChange,
  isDivEditable,
  setIsDivEditable,

  renderedDimensions,
}) => {
  const refEl = useRef(null); // Reference to the contentEditable div
  console.log("renderedDimensions", renderedDimensions);

  useEffect(() => {
    if (refEl.current && refEl.current.innerHTML !== html) {
      refEl.current.innerHTML = html;
    }
  }, [html]);

  // Emit change on input
  const emitChange = () => {
    const htmlContent = refEl.current.innerHTML;
    onChange(htmlContent);
  };

  // Handle paste event with plain text only
  const handlePaste = (event) => {
    if (
      textArea?.defaultField ||
      textArea?.elementReadOnly ||
      textArea?.customField
    ) {
      event.preventDefault(); // Prevent paste if defaultField is true
      return;
    }

    event.preventDefault(); // Prevent default paste behavior

    const clipboardData = event.clipboardData || window.clipboardData;
    const plainText = clipboardData.getData("text"); // Get plain text from clipboard

    // Insert plain text manually
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    selection.deleteFromDocument(); // Delete any selected text
    const range = selection.getRangeAt(0); // Get current range

    // Create a text node and insert it at the caret position
    range.insertNode(document.createTextNode(plainText));

    // Move caret to the end of the inserted text
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);

    emitChange(); // Trigger change after pasting
  };

  const handleDoubleClick = () => {
    setIsDivEditable(true);
    setTimeout(() => {
      // Focus on the div once it becomes editable
      if (refEl.current) {
        refEl.current.focus();
      }
    }, 0); // Use a timeout to ensure React updates the DOM first
  };

  console.log("textArea?.elementReadonly", textArea?.elementReadOnly);

  return (
    <>
      <div
        id={`contenteditable-${textArea?.id}`}
        onDoubleClick={handleDoubleClick}
        contentEditable={
          textArea?.elementReadOnly || textArea?.defaultField
            ? false
            : isDivEditable
        }
        onPaste={handlePaste} // Add paste handler
        ref={refEl}
        onInput={emitChange}
        style={{
          // Limit the width and height to not exceed renderedDimensions
          width: "100%",
          // height:`${renderedDimensions?.heigh - 2}px`,
          maxHeight: `${renderedDimensions?.height - 2}px`,
          maxWidth: `${renderedDimensions?.width - 2}px`,
          overflowWrap: "break-word", // Handles long words by breaking them to a new line
          wordWrap: "break-word",
          whiteSpace: "pre-wrap", // Preserves line breaks
          fontFamily: textArea.fontFamily,
          fontSize: textArea.fontSize,
          fontWeight: textArea.isBold ? "bold" : "normal",
          fontStyle: textArea.isItalic ? "italic" : "normal",
          textDecoration: textArea.isUnderline ? "underline" : "none",
          color: textArea.fontColor,
          textAlign: textArea.textAlign,
          overflow: "auto", // Allows scrolling if content exceeds the max height
        }}
      ></div>
    </>
  );
};
