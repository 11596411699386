import { useDispatch } from "react-redux";
import { addLine } from "../../store/lineSlice";
import { Button } from "antd";

export const AddLines = () => {
  const dispatch = useDispatch();
  return (
    <div>
      <h3>Add the line </h3>
      <Button onClick={() => dispatch(addLine({ dotted: false }))}>
        + Add Line
      </Button>
      <Button onClick={() => dispatch(addLine({ dotted: true }))}>
        + Add Dashed Line
      </Button>
    </div>
  );
};
