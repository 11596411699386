import { Button } from "antd";
import AttributeInfoModal from "./components/AttributeInfoModal";
import { useState } from "react";
import MandatoryTextAttributes from "./components/DefaultTextAttribute";
import DefaultAttributes from "./components/DynamicAttributes";
import CustomAttributes from "./components/CustomAttribute";

export const Attribute = () => {
  const [isAddAttribute, setIsAddAttribute] = useState();
  return (
    <div className="add-text-btn">
      <Button
        onClick={() => {
          setIsAddAttribute(true);
        }}
      >
        + Add Attribute
      </Button>
      <AttributeInfoModal
        isAddAttribute={isAddAttribute}
        setIsAddAttribute={setIsAddAttribute}
      />
      <MandatoryTextAttributes />
      <DefaultAttributes />
      <CustomAttributes />
    </div>
  );
};
