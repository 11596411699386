import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import interact from "interactjs";
import styled from "styled-components";
import {
  setLinePosition,
  setLineWidth,
  removeLine,
  setSelectedLineId,
} from "../../store/lineSlice";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";

// Styled component for the draggable line
const Line = styled.div`
  position: absolute;
  background-color: ${(props) =>
    props.dotted
      ? props.selected
        ? "blue"
        : "transparent"
      : props.selected
      ? "blue"
      : props.color || "black"};
  height: ${(props) => props.height || "2px"};
  width: ${(props) => props.width || "100px"};
  cursor: move;
  z-index: 20000;
  border-top: ${(props) => (props.dotted ? "2px dashed" : "none")};
  border-top-color: ${(props) => props.color || "black"};

  &:hover {
    background-color: ${(props) => (props.dotted ? "transparent" : "blue")};
  }

  .draggable-delete-btn {
    width: 10px;
    border-radius: 22px;
  }
`;

// Styled resize handles
export const ResizeHandle = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border: 1px solid grey;
  position: absolute;
  border-radius: 50%;
  z-index: 1000;
`;

const DraggableLine = ({ line, onClick }) => {
  const dispatch = useDispatch();
  const lineRef = useRef(null);
  const [selected, setSelected] = useState(false);
  const selectedLineId = useSelector((state) => state.draggableLine.selectedId);
  const zoomLevel = useSelector((state) => state.bgUploads.zoomLevel);

  useEffect(() => {
    const lineElement = lineRef.current;
    const parent = lineElement.parentElement;
  
    // Interact.js for draggable
    interact(lineElement)
      .draggable({
        listeners: {
          move(event) {
            const id = line.id;
  
            // Adjust movement with dx and dy
            const adjustedDx = event.dx / zoomLevel;
            const adjustedDy = event.dy / zoomLevel;
  
            const newX = (parseFloat(lineElement.dataset.x) || 0) + adjustedDx;
            const newY = (parseFloat(lineElement.dataset.y) || 0) + adjustedDy;
  
            // Apply transform directly without clamping
            lineElement.dataset.x = newX;
            lineElement.dataset.y = newY;
            lineElement.style.transform = `translate(${newX}px, ${newY}px)`;
  
            // Dispatch position updates
            dispatch(setLinePosition({ id, x: newX, y: newY }));
          },
        },
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: parent, // Restrict to parent container
            endOnly: true,
          }),
        ],
      })
      .resizable({
        edges: { left: true, right: true },
        listeners: {
          move(event) {
            const id = line.id;
  
            const { x, y } = lineElement.dataset;
  
            // Adjust resizing
            const adjustedWidth = event.rect.width / zoomLevel;
            const deltaX = event.deltaRect.left / zoomLevel;
  
            const newX = (parseFloat(x) || 0) + deltaX;
  
            // Update width and position
            lineElement.dataset.x = newX;
            lineElement.style.transform = `translate(${newX}px, ${y || 0}px)`;
            lineElement.style.width = `${adjustedWidth}px`;
  
            // Dispatch updates
            dispatch(setLinePosition({ id, x: newX, y: parseFloat(y) || 0 }));
            dispatch(setLineWidth({ id, width: adjustedWidth }));
          },
        },
        modifiers: [
          interact.modifiers.restrictSize({
            min: { width: 50 },
          }),
        ],
      });
  
    // Keydown event handler for arrow keys
    const handleKeyDown = (event) => {
      if (selectedLineId === line.id) {
        const step = 10 / zoomLevel; // Adjust step based on zoom
        let x = parseFloat(lineElement.dataset.x) || 0;
        let y = parseFloat(lineElement.dataset.y) || 0;
  
        switch (event.key) {
          case "ArrowUp":
            y -= step;
            break;
          case "ArrowDown":
            y += step;
            break;
          case "ArrowLeft":
            x -= step;
            break;
          case "ArrowRight":
            x += step;
            break;
          default:
            return;
        }
  
        event.preventDefault();
  
        // Update position
        lineElement.dataset.x = x;
        lineElement.dataset.y = y;
        lineElement.style.transform = `translate(${x}px, ${y}px)`;
  
        // Dispatch updates
        dispatch(setLinePosition({ id: line.id, x, y }));
      }
    };
  
    // Add and cleanup event listeners
    if (selectedLineId === line.id) {
      window.addEventListener("keydown", handleKeyDown);
    }
  
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, line.id, selectedLineId, zoomLevel]);
  
  const handleMouseEnter = () => {
    setSelected(true);
  };

  const handleMouseLeave = () => {
    setSelected(false);
  };

  return (
    <Line
      ref={lineRef}
      data-x={line.position.x}
      data-y={line.position.y}
      style={{
        transform: `translate(${line.position.x}px, ${line.position.y}px)`,
      }}
      width={`${line.width}px`}
      height={`${line.height}px`}
      color={line.color}
      selected={selected}
      dotted={line.dotted} // Pass the dotted property to Line
      className="draggable-line"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        onClick();
        dispatch(setSelectedLineId(line.id));
      }}
      tabIndex={0}
    >
      {/* Resize handles */}
      {selectedLineId === line.id && (
        <>
          <Button
            className="draggable-delete-btn"
            onClick={() => dispatch(removeLine({ id: line.id }))}
          >
            <DeleteOutlined />
          </Button>

          <ResizeHandle
            style={{
              left: -6,
              top: -4,
              cursor: "ew-resize",
            }}
          />
          <ResizeHandle
            style={{
              right: -6,
              top: -4,
              cursor: "ew-resize",
            }}
          />
        </>
      )}
    </Line>
  );
};

export default DraggableLine;
