import styled from "styled-components";

export const StyledHeaderComponent = styled.div`
  width: auto;
  height: 72px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  /* padding: 16px 29px 0px 30px; */
  border-bottom: 4px solid #f3f6fa;
  align-items: center;
  button {
        padding: 10px;
      }


  .logo-container {
    padding: 20px;
  }
  @media (max-width: 480px) {
  }
  .right-section {
    form {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      button {
        padding: 10px;
      }

      .ant-form-item {
        padding: 0;
        margin: 0;
      }
    }
  }
`;
