import React from "react";
import CertLogo from "../../assets/SvgIcons/CertLogo";
import { StyledHeaderComponent } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import UserDetailsDropDown from "../UserDetailsDropDown";
import SaveAs from "../SaveAs/SaveAs";
import { ROUTES } from "../../routes/routeConstants";
import { SaveTemplateNameForm } from "./components/SavetTemplateNameForm";
import { useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails, templateNameOnChange } = useSelector(
    (state) => state?.auth
  );
  console.log("templateNameOnChange", templateNameOnChange,userDetails?.templateName);

  console.log("userDetails", userDetails);
  return (
    <StyledHeaderComponent>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
        className="logo-container"
      >
        <CertLogo />
      </div>

      <div
        style={{ display: "flex", alignItems: "center", gap: "20px" }}
        className="right-section"
      >
        {location?.pathname === ROUTES.EDITOR && (
          <>
            {(userDetails?.templateName || templateNameOnChange) && (
              <SaveTemplateNameForm
                templateName={userDetails?.templateName || templateNameOnChange}
              />
            )}
            <SaveAs />
          </>
        )}

        <UserDetailsDropDown />
      </div>
    </StyledHeaderComponent>
  );
};

export default Header;
