import React, { useEffect, useRef, useState } from "react";
import PlusIcon from "../../../assets/SvgIcons/PlusIcon";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import {
  CertificateImage,
  CertificateWrapper,
  ScrollButton,
  ScrollContainer,
  ScrollContainerWrapper,
  TemplateCreateStyled,
} from "../style";
import CreateCertificateModal from "./CreateCertificateModal";
import { useNavigate } from "react-router-dom";
import { useGetDefaultTemplateDataQuery } from "../../../service/templateUpdateApi";
import useTemplateData from "../../../hooks/setTemplateData";
import { Skeleton } from "antd";
import { setActiveTab } from "../../../store/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query";

const TemplateSlider = () => {
  const scrollRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [isTemplateReady, setTemplateReady] = useState(false);
  const [areImagesLoaded, setAreImagesLoaded] = useState(false);
  const authToken = useSelector((state) => state.auth.token);
  const [isDefault, setIsDefault] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading: isDefaultTemplatesLoading } =
    useGetDefaultTemplateDataQuery(
      authToken ? { limit: 20, skip: 0 } : skipToken
    );
  const { isLoading, resetState } = useTemplateData(selectedTemplateId);

  const handleScroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === "left" ? -300 : 300;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const updateData = data?.data?.map((item, index) => ({
    ...item,
    title: `Certificate ${index + 1}`,
  }));

  useEffect(() => {
    if (!updateData?.length) return;

    const images = new Promise((resolve) => {
      const validImages = updateData
        ?.filter((item) => item.templateUrl)
        .map(() => new Image());
      let loadedCount = 0;

      validImages.forEach((image, index) => {
        image.onload = () => {
          loadedCount++;
          if (loadedCount === validImages.length) {
            resolve();
          }
        };
        image.src = updateData.filter((item) => item.templateUrl)[
          index
        ].templateUrl;
      });

      if (!validImages.length) {
        resolve();
      }
    });

    images.then(() => {
      setAreImagesLoaded(true);
    });
  }, [updateData]);

  const handleTemplateSelect = (id) => {
    resetState();
    setSelectedTemplateId(id);
    setTemplateReady(true);
    dispatch(setActiveTab("1"));
    setIsDefault(true);
  };

  const handleImageError = (e) => {
    console.log("e from error ", e);
    e.target.src = "/images/no-img.png"; // set your default image path here
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isTemplateReady && !isLoading) {
      navigate(`/editor`, {
        state: {
          from: "editDefault",
          id: selectedTemplateId,
          isDefault: isDefault,
        },
      });
      setTemplateReady(false);
    }
  }, [isTemplateReady, isLoading, navigate, selectedTemplateId, isDefault]);

  return (
    <>
      <div className="template-container" style={{ display: "flex" }}>
        <TemplateCreateStyled>
          <div className="template" onClick={() => setIsModalVisible(true)}>
            <PlusIcon />
            <span>Create Template</span>
          </div>
        </TemplateCreateStyled>
        <ScrollContainerWrapper>
          <ScrollButton direction="left" onClick={() => handleScroll("left")}>
            <CaretLeftOutlined />
          </ScrollButton>

          <ScrollContainer ref={scrollRef}>
            {isDefaultTemplatesLoading || !areImagesLoaded
              ? Array.from({ length: 9 }).map((_, index) => (
                  <CertificateWrapper key={index}>
                    <Skeleton.Image
                      style={{ width: 160, height: 120 }}
                      active
                    />
                    <Skeleton.Input
                      style={{ width: 160, marginTop: 8 }}
                      active
                      size="small"
                    />
                  </CertificateWrapper>
                ))
              : updateData?.map((certificate, index) => (
                  <CertificateWrapper
                    onClick={() => handleTemplateSelect(certificate?._id)}
                    key={certificate.id}
                  >
                    <CertificateImage
                      src={certificate.templateUrl ?? "images/no-img.png"}
                      alt={certificate.title}
                      onError={handleImageError} // Set default on error
                    />
                    <span className="certificate-title">
                      {certificate.title}
                    </span>
                    <span className="edit-icon">
                      <img width={25} src="/images/Edit.svg" alt="" />
                    </span>
                  </CertificateWrapper>
                ))}
          </ScrollContainer>

          <ScrollButton direction="right" onClick={() => handleScroll("right")}>
            <CaretRightOutlined />
          </ScrollButton>
        </ScrollContainerWrapper>
      </div>
      <CreateCertificateModal
        isModalVisible={isModalVisible}
        handleClose={handleClose}
      />
    </>
  );
};

export default React.memo(TemplateSlider);
