import { Button } from "antd";
import html2canvas from "html2canvas";
import React from "react";
// import { generateDOM } from "../../includes/utils";
import { useSelector } from "react-redux";
import { generateDOM } from "../../includes/utils";
import { DownloadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
const preloadImage = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;
    img.onload = () => resolve(url);
    img.onerror = reject;
  });

const DownloadTemplate = () => {
  const textData = useSelector((state) => state.textEditor);
  const lineData = useSelector((state) => state.draggableLine);
  const zoomLevel = useSelector((state) => state.bgUploads.zoomLevel);
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  const imageData = useSelector((state) => state.uploads);
  const backgroundImageData = useSelector((state) => state.bgUploads.image);
  const templateDimension = useSelector((state) => state.templateDimension);

  const downloadPreviewAsImage = async () => {
    const previewSection = document.querySelector(".certiificate-container");

    console.log("previewSection", previewSection);
    const bgImageUrl = backgroundImageData.url;
    if (bgImageUrl) await preloadImage(bgImageUrl);

    const containerData = generateDOM([
      lineData,
      textData,
      imageData,
      backgroundImageData,
      {
        width: templateDimension?.width,
        height: templateDimension?.height,
        zoomLevel: templateDimension?.zoomLevel,
        actualZoom: zoomLevel,
      },
      {
        type: "preview",
        details: userDetails,
      },
    ]);
    console.log("template data", containerData);
    const resultContainer = document.getElementById("template-result");
    resultContainer.innerHTML = "";
    resultContainer.append(containerData);

    if (resultContainer) {
      console.log("resultContainer", resultContainer);
      html2canvas(resultContainer, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
      }).then((canvas) => {
        // Get the scaled canvas dimensions

        const scaledWidth = canvas.width;
        const scaledHeight = canvas.height;
        console.log("scaledWidth", scaledHeight, scaledWidth);

        // Create a new canvas element to resize the image to original dimensions
        const resizedCanvas = document.createElement("canvas");
        const context = resizedCanvas.getContext("2d");

        // Set the resized canvas dimensions back to original container size
        resizedCanvas.width = templateDimension.width; // Original width of the certificate container
        resizedCanvas.height = templateDimension.height; // Original height of the certificate container

        // Draw the scaled canvas onto the resized canvas
        context?.drawImage(
          canvas,
          0,
          0,
          scaledWidth,
          scaledHeight,
          0,
          0,
          templateDimension.width,
          templateDimension.height
        );
        console.log("resizedCanvas", resizedCanvas);
        // Convert the resized canvas to a downloadable image
        const link = document.createElement("a");
        link.href = resizedCanvas.toDataURL("image/png");
        link.download = "certiificate-container.png";
        link.click(); // Trigger the download
      });
    }
  };
  console.log("backgroundImageData", backgroundImageData);
  return (
    <>
      <Button
        // type="dashed"
        style={{ fontSize: 16 }}
        onClick={() => {
          if (!backgroundImageData) {
            toast.error("Please choose a background to proceed !");
            return;
          } else {
            downloadPreviewAsImage();
          }
        }}
      >
        <DownloadOutlined />
        Download
      </Button>
    </>
  );
};

export default DownloadTemplate;
