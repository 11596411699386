import React, { useCallback, useEffect, useState, useRef } from "react";
import TemplateSlider from "./component/TemplateSlider";
import { HomeStyled } from "./style";

import { useLocation } from "react-router-dom";
import {
  setTemplateNameOnChange,
  setUserDetails,
  useGenerateOtpMutation,
} from "../../service/auth";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import UserOtpModal from "./component/UserOtpModal/UserOtpModal";

import Spinner from "../../components/spinner/Spinner";
import { isTokenExpired } from "../../helper/jwtHelper";

import { TemplatesTab } from "./component/TemplatesTab";
import { setZoomLevel } from "../../store/BgSlice";
import { skipToken } from "@reduxjs/toolkit/query";

const HomePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");
  const dispatch = useDispatch();
  const [isOtpLoader, setIsOtpLoader] = useState(false);

  const [otpData, setOtpData] = useState(false);
  const prevOtpDataRef = useRef(null); // Reference to store the previous otpData
  useEffect(() => {
    dispatch(setTemplateNameOnChange(null));
    dispatch(setZoomLevel(1));
  }, []);

  const { isLoggedIn, userDetails, token } = useSelector(
    (state) => state?.auth
  );
  console.log("userId", !userId);
  const [generateOtp, { isLoading, isSuccess, isError, error }] =
    useGenerateOtpMutation({ skipToken: !userId });

  const sendOtp = useCallback(async () => {
    try {
      const response = await generateOtp({ userId }).unwrap();

      if (response?.success) {
        setOtpData(response?.uuid);
        dispatch(setUserDetails({}));
        localStorage.removeItem("userDetails");
        localStorage.removeItem("token");
        toast.success(response?.messsage);
      } else {
        toast.error(response?.error?.data?.message[0]);
      }
    } catch (error) {
      console.error("Error generating OTP:", error);
    }
  }, [dispatch, generateOtp, userId]);

  useEffect(() => {
    // Check if otpData has changed and is not null before calling sendOtp
    if (prevOtpDataRef.current === null && prevOtpDataRef.current !== otpData) {
      if (
        !(userDetails?._id?.toString() === userId && !isTokenExpired(token))
      ) {
        sendOtp();
      }
    }
    // Update the ref with the current otpData after each render
    prevOtpDataRef.current = otpData;
  }, [isLoading, otpData, sendOtp, token, userDetails?._id, userId]);

  useEffect(() => {
    console.log("otpData testing", otpData);
  }, [otpData]);

  useEffect(() => {
    if (!userId && !isLoggedIn) {
      window.location.href =
        process.env.REACT_APP_INSTITUTE_URL || "http://3.221.198.94:3002/"; // Replace with your external URL
    }
  }, [isLoggedIn, userId]);
  console.log("otpData", otpData);
  return (
    <>
      {(isLoading || isOtpLoader) && <Spinner />}
      <HomeStyled>
        <h1>Browse Uploaded Templates</h1>
        {otpData && (
          <UserOtpModal
            userId={userId}
            otpData={otpData}
            setIsOtpLoader={setIsOtpLoader}
          />
        )}
        <TemplateSlider />
        <TemplatesTab />
      </HomeStyled>
    </>
  );
};

export default React.memo(HomePage);
