import styled from "styled-components";

export const StyledImageWrapper = styled.div`
  .image-grid {
    margin-top: 20px;
    max-height: 40vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .image-item {
    position: relative;
    border: 3px solid black;
    border-radius: 10px;
    width: 135px;
    margin-top: 10px;
    padding: 5px;

    &:hover {
      border-radius: 8px;
      background-color: #f1eee8;
    }
  }

  .image-item img {
    width: 125px;
    height: 125px;
    padding: 5px;
  }

  .image-item button {
    position: absolute;
    left: 98px;
    top: 3px;
    /* right: 5px;  */
  }

  .sub-heading {
    margin-top: 10px;
    font-size: 14px;
    color: gray;
    text-align: center;
    
  }
`;

export const FileUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  .file-upload {
    display: flex;
    align-items: center;
    justify-content: inherit;
    width: 200px;
    height: 50px;
    border: 1px solid #3c3c3c;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    text-align: center;
    transition: border 0.2s ease;
    gap: 10px;

    &:hover {
      box-shadow: gray 1;
    }
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .upload-icon {
    font-size: 24px;
    margin-bottom: 5px;
  }
`;
