// components/ImageGrid.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeImage } from "../../store/ImageSlice";
import { Badge, Button, Col, Row } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteduplicateElements } from "../../helper/removeDuplicates";

const ImageGrid = () => {
  const images = useSelector((state) => state.uploads.images);
  const dispatch = useDispatch();
  console.log("images", images);
  const deduplicatedData = deleteduplicateElements(images);
  console.log('deduplicatedData', deduplicatedData)

  const handleRemove = (id) => {
    dispatch(removeImage(id));
  };

  return (
    <div className="image-grid">
      <Row wrap={true}>
        {deduplicatedData.map((image) => (
          <Col gap={10} md={24} lg={12}>
            <div key={image.id} className="image-item">
              {image?.elementReadOnly ? (
                <Badge.Ribbon
                  text="Default"
                  color="blue"
                  Badge
                  style={{ marginTop: -15 }}
                >
                  <img src={image.url} alt="" />
                </Badge.Ribbon>
              ) : (
                <img src={image.url} alt="" />
              )}

              {image?.elementReadOnly ? (
                ""
              ) : (
                <Button onClick={() => handleRemove(image.id)}>
                  <DeleteOutlined />
                </Button>
              )}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ImageGrid;
