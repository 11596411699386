import * as React from "react";
const TextIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={40}
    fill="currentColor"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M10.092 14.545h1.818s0-3.636 1.818-3.636h5.455v16.364s0 1.818-1.819 1.818h-1.818v1.818H26.455v-1.818h-1.818c-1.818 0-1.818-1.818-1.818-1.818V10.909h5.455c1.818 0 1.818 3.636 1.818 3.636h1.818V9.091H10.092v5.454ZM2.818 1.818h1.818V0H1v3.636h1.818V1.818ZM1 40h3.636v-1.818H2.818v-1.818H1V40ZM2.818 7.273H1v3.636h1.818V7.273ZM2.818 14.545H1v3.637h1.818v-3.637ZM2.818 21.818H1v3.637h1.818v-3.637ZM2.818 29.09H1v3.637h1.818v-3.636ZM37.363 40H41v-3.636H39.18v1.818h-1.818V40ZM41 0h-3.637v1.818h1.819v1.818H41V0ZM41 29.09h-1.818v3.637H41v-3.636ZM41 21.818h-1.818v3.637H41v-3.637ZM41 14.545h-1.818v3.637H41v-3.637ZM41 7.273h-1.818v3.636H41V7.273ZM11.91 38.182H8.273V40h3.637v-1.818ZM19.181 38.182h-3.636V40h3.636v-1.818ZM26.455 38.182h-3.637V40h3.637v-1.818ZM33.728 38.182h-3.636V40h3.636v-1.818ZM11.91 0H8.273v1.818h3.637V0ZM19.181 0h-3.636v1.818h3.636V0ZM26.455 0h-3.637v1.818h3.637V0ZM33.728 0h-3.636v1.818h3.636V0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h40v40H.5z" />
      </clipPath>
    </defs>
  </svg>
);
export default TextIcon;
