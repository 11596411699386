import { Form, Input } from "antd";
import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { CommonButton } from "../../../style/globalStyle";
import { useDispatch, useSelector } from "react-redux";
import { addTextArea } from "../../../store/textEditorSlice";
import replaceSpacesWithUnderscore from "../../../helper/substring";

const AttributeInfoModal = ({ isAddAttribute, setIsAddAttribute }) => {
  const { textAreas } = useSelector((state) => state.textEditor);

  const dispatch = useDispatch();
  const handleClose = () => {
    setIsAddAttribute(false);
  };
  const handleAttribute = async (values) => {
    console.log("values", values);
    const val = replaceSpacesWithUnderscore(values?.attribute_name);

    dispatch(
      addTextArea({
        id: `default-${val}`,
        content: `[custom.${val}]`,
        customField: true,
        enabled: true,
        width: "auto",
      })
    );
    setIsAddAttribute(false);
  };
  const [form] = Form.useForm();

  return (
    <div>
      <CustomModal
        visible={isAddAttribute}
        onClose={handleClose}
        title="Attribute Info"
        width={600}
        closable={true}
      >
        <div className="attribute-form-container">
          <span className="header">
            Please provide details for the Attribute
          </span>
          <Form
            requiredMark={false}
            layout="vertical"
            form={form}
            onFinish={handleAttribute}
          >
            <Form.Item
              label="Text Attribute Name"
              name="attribute_name"
              className="center-error-message"
              rules={[
                { required: true, message: "Please enter attribute name !" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
              <CommonButton
                htmlType="submit"
                fontSize="18px"
                primary
                color="#FFF"
                width="100%"
                // onClick={handleClose}
              >
                Add Attribute
              </CommonButton>
            </Form.Item>
          </Form>
        </div>
      </CustomModal>
    </div>
  );
};
export default React.memo(AttributeInfoModal);
