import * as React from "react";
const BackgroundIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    fill="currentColor"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      fill="currentColor"
      d="M50 78a3.999 3.999 0 0 0 2.83-1.17l24-24A4 4 0 0 0 78 50v-9.66L40.34 78H50ZM77.998 26a4 4 0 0 0-6.83-2.83l-48 48a4.003 4.003 0 0 0 5.66 5.66l48-48a4 4 0 0 0 1.17-2.83ZM74 78a4 4 0 0 0 4-4v-9.66L64.34 78H74ZM50 22a4 4 0 0 0-2.83 1.17l-24 24A4 4 0 0 0 22 50v9.66L59.66 22H50ZM26 22a4 4 0 0 0-4 4v9.66L35.66 22H26Z"
    />
  </svg>
);
export default BackgroundIcon;
