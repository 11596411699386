import { ConfigProvider, Table } from "antd";

const CustomTable = ({ data, isLoading, columns }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#fff",
            borderColor: "rgba(159, 173, 189, 0.30)",
            headerSplitColor: "transparent",
          },
        },
      }}
    >
      <Table
        bordered={true}
        headerColor={"green"}
        headerBg={"blue"}
        tailor={false}
        dataSource={data || []}
        columns={columns}
        loading={isLoading}
        pagination={false}
        className=""
      />
    </ConfigProvider>
  );
};

export default CustomTable;
