export const deleteduplicateElements = (elements) => {
  const seen = new Set();
  return elements.filter((element) => {
    const uniqueKey = `${element.id}`;
    if (seen.has(uniqueKey)) {
      return false;
    }
    seen.add(uniqueKey);
    return true;
  });
};
