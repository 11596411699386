// components/FileUpload.js
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { addImages } from "../../store/ImageSlice";
import { UploadOutlined } from "@ant-design/icons";
import { FileUploadWrapper } from "./styles";
import { message } from "antd";

const FileUpload = () => {
  const dispatch = useDispatch();

  const handleFileUpload = (e) => {
    const filesArray = Array.from(e.target.files);
    const validFiles = [];

    filesArray.forEach((file, index) => {
      const isValidFileType =
        file.type === "image/png" || file.type === "image/jpeg";
      const isValidFileSize = file.size <= 1024 * 1024; // 1 MB limit

      if (isValidFileType && isValidFileSize) {
        validFiles.push({
          id: index + Date.now(),
          url: URL.createObjectURL(file),
          file,
        });
      } else {
        // Handle invalid file cases, e.g., show an alert or message
        if (!isValidFileType) {
          message.error(`File ${file.name} is not a valid PNG or JPEG image.`);
        } else if (!isValidFileSize) {
          message.error(`File ${file.name} exceeds the 1 MB size limit.`);
        }
      }
    });

    if (validFiles.length > 0) {
      dispatch(addImages(validFiles));
    }

    // Reset the file input so the same file can be uploaded again
    e.target.value = null;
    console.log("validFiles", validFiles);
  };

  return (
    <FileUploadWrapper>
      <div className="file-upload">
        <input
          type="file"
          multiple
          accept="image/png, image/jpeg"
          onChange={handleFileUpload}
        />
        <UploadOutlined />
        <div>Choose a file...</div>
      </div>
    </FileUploadWrapper>
  );
};

export default FileUpload;
