import { Form, Input } from "antd";
import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { CommonButton } from "../../../style/globalStyle";
import { useDispatch, useSelector } from "react-redux";
import { addTextArea } from "../../../store/textEditorSlice";
import replaceSpacesWithUnderscore from "../../../helper/substring";

const SaveAsModal = ({
  templateSelected,
  isModalVisible,
  setIsModalVisible,
  dataHandler,
  setSaveAsFinal,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsModalVisible(false);
  };
  const handleAttribute = (values) => {};

  return (
    <div>
      <CustomModal
        visible={isModalVisible}
        onClose={handleClose}
        title="Save Template"
        width={600}
        closable={true}
      >
        <div className="attribute-form-container">
          <span className="header">
            <p style={{ textAlign: "center", fontSize: "20px" }}>
              Would you like to save this as a draft or as a final version?
            </p>
            <ul style={{ marginLeft: "60px" }}>
              {templateSelected ? (
                <li>
                  <span style={{ fontWeight: "bold" }}>Save Changes</span>: You
                  can save current changes.
                </li>
              ) : (
                <li>
                  <span style={{ fontWeight: "bold" }}>Save as Draft</span>: You
                  can continue editing later.
                </li>
              )}
              <li>
                <span style={{ fontWeight: "bold" }}>Save as Final</span>: This
                will mark the content as complete..
              </li>
            </ul>
          </span>
          <div className="button-container">
            {!templateSelected ? (
              <CommonButton
                htmlType="submit"
                fontSize="18px"
                primary
                color="#FFF"
                width="100%"
                onClick={() => dataHandler("draft")}
              >
                Save As Draft
                <span>
                  <img width={20} src="/images/saveAsDraft.svg" alt="" />
                </span>
              </CommonButton>
            ) : (
              <CommonButton
                htmlType="submit"
                fontSize="18px"
                primary
                color="#FFF"
                width="100%"
                onClick={() => dataHandler("edit")}
              >
                Save Changes
                <span>
                  <img width={20} src="/images/saveAsChanges.svg" alt="" />
                </span>
              </CommonButton>
            )}
            <CommonButton
              htmlType="submit"
              fontSize="18px"
              primary
              color="#FFF"
              width="100%"
              onClick={() => {
                dataHandler("final");
              }}
            >
              Save As Final
              <span>
                <img width={20} src="/images/saveAsFinal.svg" alt="" />
              </span>
            </CommonButton>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};
export default React.memo(SaveAsModal);
