import styled from "styled-components";

export const HomeStyled = styled.div`
  margin: 20px;

  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      font-size: 1.5rem !important;
      font-weight: bold !important;
      color: black !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-size: 1.5rem !important;
      font-weight: bold !important;
      color: #006699 !important;
    }
  }
  .wallet-card {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 780px) {
    .template-container {
      flex-direction: column;
    }
  }
`;

export const TemplateCreateStyled = styled.div`
  width: 15%;
  height: 192px;
  border: 4px dotted #006699;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 10px;
  box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
    rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
    rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;
  cursor: pointer;
  .template {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    span {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 16.32px;
      text-align: left;
      color: #006699;
    }
  }
  @media screen and (max-width: 780px) {
    width: 100%;
    margin: 0;
  }
`;

export const ScrollContainerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 85%;
  &:hover button {
    opacity: 1;
  }
  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 100%;
  padding: 10px;

  &::-webkit-scrollbar {
    display: none; // Hide default scrollbar
  }
`;

export const CertificateWrapper = styled.div`
  flex-shrink: 0;
  width: 200px;
  margin-right: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
  .anticon,
  .anticon-edit {
    position: absolute;
    top: 2px;
    right: 0;
    font-size: 22px;
  }
  .edit-icon {
    position: absolute;
    /* top: 0; */
    bottom: -5px;
    right: 22px;
  }
`;
export const BgSkeletonWrapper = styled.div`
  flex-shrink: 0;

  border: 3px solid black;
  border-radius: 10px;
  width: 135px;
  margin-top: 10px;
  padding: 5px;
  .ant-skeleton {
    width: 100%;
  }
  .ant-skeleton-input {
    width: 100% !important;
  }
`;

export const CertificateImage = styled.img`
  /* width: 100%; */
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-bottom: 10px;
  padding: 10px;

  &:hover {
    transform: scale(1.05);
  }

  .certificate-title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: center;
  }
`;

export const ScrollButton = styled.button`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background: #006699;
  color: white;
  border: none;
  border-radius: 10%;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #006699;
  }

  ${(props) => (props.direction === "left" ? `left: 10px;` : `right: 10px;`)}
`;

export const DraftTemplateTableStyled = styled.div`
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-container {
      .sort-container {
        width: 94px;
        height: 40px;
        border: 1px solid #006699;
        border-radius: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #006699;

        span {
          font-family: Roboto;
          font-size: 18px;
          font-weight: 500;
          line-height: 16.41px;
        }
      }
    }
  }
  .ant-pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
`;
