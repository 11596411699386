import { Modal } from "antd";
import styled from "styled-components";

export const StyledCustomModal = styled(Modal)`
  .ant-modal-close {
    top: 30px !important;
    right: 30px !important;
  }
  .ant-modal-content {
    border-radius: 14px;
    border: 2px solid #e7e7e7;
    backdrop-filter: blur(2px);
    max-width: ${(props) => props.width || "50%"};
    padding: 25px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
      rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
      rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;
    border: 1px solid grey;
    border: 2px solid #e7e7e7 !important;
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);

    border-radius: 14px;

    @media (max-width: 768px) {
      width: 100%;
    }
    .note {
      color: #069;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .ant-modal-body {
      max-width: 100% !important;
      height: 100% !important;
      background: #fff;
      border-radius: 14px;
      width: 100%;
      height: 100%;

      .title {
        border-bottom: 2px solid #f3f6fa;
        text-align: center;
        h2 {
          /* padding: 20px 20px; */
          color: #000;
          font-family: Poppins;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          line-height: 102%; /* 32.64px */
        }
      }
      .child-container {
        padding: 10px 30px;
        .button-container {
          display: flex;
          gap: 25px;
          margin-top: 20px;
          /* margin-bottom: 35px; */
        }
      }

      .sub-heading {
        margin-left: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          text-align: left;
          color: #3b4856;
        }
      }

      .divider-line {
        text-align: center;
        margin: 10px 0px;
        color: #069;
        span {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 21px;
        }
      }
    }
  }
  #otp_help {
    .ant-form-item-explain-error {
      text-align: center;
      margin-top: 10px;
    }
  }

  .attribute-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .button-container {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      margin-bottom: 30px;
      margin-top: 30px;

      button {
        padding: 10px;
        align-items: center;
        display: flex !important;
        span {
          display: flex;
          align-items: center;
        }
      }
    }
    .header {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 600;
    }
    form {
      width: 80% !important;
      margin-top: 20px !important;
      label {
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
`;
