import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { CommonButton, StyledForm } from "../../../style/globalStyle";
import { Form, Input, Select, Radio } from "antd";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../../store/loadingSlice";
import { useNavigate } from "react-router-dom";
import { setTemplateDimensions } from "../../../store/templateDimesnionSlice";
import { resetImages } from "../../../store/ImageSlice";
import { resetTextAreas } from "../../../store/textEditorSlice";
import { resetLines } from "../../../store/lineSlice";
import { resetBgImage, setRestZoomLevel } from "../../../store/BgSlice";
import {
  setTemplateName,
  setTemplateNameOnChange,
} from "../../../service/auth";

const CreateCertificateModal = ({ isModalVisible, handleClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedSize, setSelectedSize] = useState("default");
  const [isCustomSize, setIsCustomSize] = useState(false);
  const [unit, setUnit] = useState("px"); // Unit state
  const navigate = useNavigate();

  const PX_TO_INCH = 96; // Conversion factor (96 pixels = 1 inch)

  const option = useMemo(
    () => [
      {
        value: "default",
        label: "Default",
        width: 890,
        height: 592,
      },
      {
        value: "large-size",
        label: "Large Size",
        width: 1200,
        height: 800,
      },
      {
        value: "A4",
        label: "A4",
        width: 595,
        height: 842,
      },
      {
        value: "custom",
        label: "Custom Size",
      },
    ],
    []
  );

  const handleSizeChange = useCallback(
    (value) => {
      console.log("unit", unit);
      setSelectedSize(value);

      if (value === "custom") {
        setIsCustomSize(true);
        form.setFieldsValue({
          width: "",
          height: "",
        });
      } else {
        setIsCustomSize(false);
        const selectedOption = option.find((opt) => opt.value === value);
        if (selectedOption) {
          form.setFieldsValue({
            width: selectedOption.width,
            height: selectedOption.height,
          });
        }
      }
    },
    [form, option, unit] // Include `unit` in dependencies
  );

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    const currentValues = form.getFieldsValue(["width", "height"]);

    // Convert values based on the selected unit
    const convertValue = (value, toUnit) => {
      if (!value || isNaN(value)) return value;
      return toUnit === "px"
        ? Math.round(value * PX_TO_INCH) // Inches to pixels
        : (value / PX_TO_INCH).toFixed(2); // Pixels to inches
    };

    setUnit(newUnit);
    const newWidth = convertValue(currentValues.width, newUnit);
    const newHeight = convertValue(currentValues.height, newUnit);

    // Update the form fields
    form.setFieldsValue({
      width: newWidth,
      height: newHeight,
    });

    // Manually trigger validation after changing the unit
    form.validateFields(["width", "height"]);
  };

  useEffect(() => {
    if (selectedSize !== "custom") {
      const selectedOption = option.find((opt) => opt.value === selectedSize);

      if (selectedOption) {
        // Convert values based on the selected unit
        const convertValue = (value, toUnit) => {
          if (!value || isNaN(value)) return value;
          return toUnit === "px"
            ? value // No conversion needed if unit is "px"
            : (value / PX_TO_INCH).toFixed(2); // Convert pixels to inches
        };

        form.setFieldsValue({
          width: convertValue(selectedOption.width, unit), // Convert width based on unit
          height: convertValue(selectedOption.height, unit), // Convert height based on unit
        });
      }
    }
  }, [selectedSize, form, option, unit]); // Include `unit` in dependencies

  const onFinish = (values) => {
    const finalWidth =
      unit === "inches" ? values.width * PX_TO_INCH : values.width;
    const finalHeight =
      unit === "inches" ? values.height * PX_TO_INCH : values.height;

    dispatch(setTemplateName(values.name));
    dispatch(
      setTemplateDimensions({
        width: Number(finalWidth),
        height: Number(finalHeight),
      })
    );
    dispatch(resetImages());
    dispatch(resetTextAreas());
    dispatch(resetLines());
    dispatch(resetBgImage());
    dispatch(setRestZoomLevel());
    localStorage.removeItem("templateId");
    navigate("/editor");
  };

  const onClose = () => {
    form.resetFields();
    handleClose();
    const selectedOption = option.find((opt) => opt.value === selectedSize);
    if (selectedOption) {
      form.setFieldsValue({
        width: selectedOption.width,
        height: selectedOption.height,
      });
    }
  };

  const handleChange = useCallback(() => {
    console.log("unit=====Changed", unit);
    form.validateFields(["width"]);
  }, [form, unit]);

  return (
    <div>
      <CustomModal
        visible={isModalVisible}
        onClose={onClose}
        title="Create Certificate"
        width={600}
      >
        <div className="sub-heading">
          <span>Personalize Certificate</span>
        </div>
        <StyledForm
          requiredMark={false}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            style={{ width: "100%" }}
            name="name"
            label="Template name"
            rules={[
              {
                required: true,
                message: "Please input name for the template !",
              },
              {
                min: 4,
                message: "Template name must be at least 4 characters long!",
              },
              {
                max: 40,
                message: "Template name cannot exceed 40 characters!",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  name: e.target.value.replace(/^\s+/, ""),
                });
                const value = e.target.value.replace(/^\s+/, "");
                dispatch(setTemplateNameOnChange(value));
              }}
            />
          </Form.Item>
          <Form.Item label="Certificate Size">
            <Select
              value={selectedSize}
              options={option.map(({ value, label }) => ({
                value,
                label,
              }))}
              onChange={handleSizeChange}
            />
          </Form.Item>

          <Form.Item label="Unit">
            <Radio.Group onChange={handleUnitChange} value={unit}>
              <Radio value="px">Pixels (px)</Radio>
              <Radio value="inches">Inches</Radio>
            </Radio.Group>
          </Form.Item>

          <div style={{ display: "flex", gap: 50 }}>
            <Form.Item
              label="Width"
              name="width"
              rules={[
                {
                  required: true,
                  message: "Please input the correct width!",
                },
                {
                  validator(_, value) {
                    if (!value || (/^\d+(\.\d+)?$/.test(value) && value > 0)) {
                      if (unit === "inches" && value < 3) {
                        return Promise.reject(
                          new Error("Width must be greater than 3 inches!")
                        );
                      }
                      if (unit === "px" && value < 288) {
                        return Promise.reject(
                          new Error(
                            "Width must be greater than 288 pixels (3 inches)!"
                          )
                        );
                      }
                      if (unit === "inches" && value > 100) {
                        return Promise.reject(
                          new Error("Width must be less than 100 inches!")
                        );
                      }
                      if (unit === "px" && value > 9600) {
                        // 100 inches in pixels (100 * 96)
                        return Promise.reject(
                          new Error(
                            "Width must be less than 9600 pixels (100 inches)!"
                          )
                        );
                      }
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Width must be a positive number greater than 0!"
                      )
                    );
                  },
                },
              ]}
            >
              <Input
                onChange={handleChange}
                addonAfter={unit}
                type="number"
                disabled={!isCustomSize}
              />
            </Form.Item>

            <Form.Item
              label="Height"
              name="height"
              rules={[
                {
                  required: true,
                  message: "Please input the correct height!",
                },
                {
                  validator(_, value) {
                    if (!value || (/^\d+(\.\d+)?$/.test(value) && value > 0)) {
                      if (unit === "inches" && value < 4) {
                        return Promise.reject(
                          new Error("Height must be greater than 4 inches!")
                        );
                      }
                      if (unit === "px" && value < 384) {
                        return Promise.reject(
                          new Error(
                            "Height must be greater than 384 pixels (4 inches)!"
                          )
                        );
                      }
                      if (unit === "inches" && value > 100) {
                        return Promise.reject(
                          new Error("Height must be less than 100 inches!")
                        );
                      }
                      if (unit === "px" && value > 5000) {
                        return Promise.reject(
                          new Error("Height must be less than 5000 pixels!")
                        );
                      }
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Height must be a positive number greater than 0!"
                      )
                    );
                  },
                },
              ]}
            >
              <Input
                onChange={handleChange}
                addonAfter={unit}
                type="number"
                disabled={!isCustomSize}
              />
            </Form.Item>
          </div>

          <div className="button-container">
            <CommonButton
              htmlType="submit"
              fontSize="18px"
              primary
              color="#FFF"
              width="500px"
              onClick={() => {
                dispatch(setActiveTab("1"));
              }}
            >
              Create
            </CommonButton>
          </div>
        </StyledForm>
      </CustomModal>
    </div>
  );
};

export default CreateCertificateModal;
