import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routeConstants";
import { StyledUserDetailsDropdown } from "./styles";
import { Button, Dropdown } from "antd";
import { capitalizeFirstLetter } from "../../helper/substring";

const UserDetailsDropdown = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  console.log("userDetails", userDetails);

  const handleSignOut = useCallback(() => {
    localStorage.clear();
    window.location.href =
      process.env.REACT_APP_INSTITUTE_URL || "http://3.221.198.94:3002/";
  }, []);

  const items = [
    {
      key: "1",
      label: (
        <>
          <div className="user-card ">
            <h2 className="font-poppins500">
              {capitalizeFirstLetter(userDetails?.name)}
            </h2>
            <p className="font-poppins500">{userDetails?.email}</p>
          </div>
        </>
      ),
    },

    {
      key: "3",
      label: (
        <>
          <p onClick={handleSignOut} className="header-content font-poppins500">
            <img src="/images/signout.svg" alt="" />
            Sign Out
          </p>
        </>
      ),
    },
  ];

  return (
    <StyledUserDetailsDropdown>
      {location?.pathname !== ROUTES.SETPASSWORD &&
        location.pathname !== ROUTES.LOGIN &&
        location?.pathname !== ROUTES.FORGET && (
          <div style={{ display: "flex" }}>
            <div className="dropdown-section">
              <Dropdown
                menu={{
                  items,
                }}
                placement="bottom"
                // open={true}
                getPopupContainer={(trigger) => trigger.parentElement}
                // dropdownAlign={{ offset: [-40, 4] }}
                // overlayStyle={{ minWidth: 200 }}
                // align={{ offset: [-40, 4] }}
              >
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  className="header-button font-poppins500 font-blue"
                >
                  {capitalizeFirstLetter(userDetails?.name)}{" "}
                  <img src="/images/downOutlined.svg" alt="" />
                </Button>
              </Dropdown>
            </div>
          </div>
        )}
    </StyledUserDetailsDropdown>
  );
};

export default React.memo(UserDetailsDropdown);
