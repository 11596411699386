import { Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGenerateInstituteTemplateMutation,
  useGenerateTemplateMutation,
  useGetTemplateDataQuery,
  usePreviewUploadDefaultMutation,
  usePreviewUploadImagesMutation,
  useUpdateExistingTemplateMutation,
  useUploadTemplateImagesMutation,
} from "../../service/templateUpdateApi";
import { setLines } from "../../store/lineSlice";
import {
  addImages,
  updateImageCoordinates,
  updateImageDimensions,
} from "../../store/ImageSlice";
import { setTextAreas } from "../../store/textEditorSlice";
import { setBgImage } from "../../store/BgSlice";
import { generateDOM } from "../../includes/utils";
import html2canvas from "html2canvas";
import Spinner from "../spinner/Spinner";
import { setTemplateDimensions } from "../../store/templateDimesnionSlice";
import { toast } from "react-toastify";
import { CommonButton } from "../../style/globalStyle";
import SaveModal from "./SaveAsModal/SaveModal";
import { setTemplateName } from "../../service/auth";
import { useLocation } from "react-router-dom";
const preloadImage = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;
    img.onload = () => resolve(url);
    img.onerror = reject;
  });

const SaveAs = () => {
  const location = useLocation();
  const image = useSelector((state) => state.bgUploads.image);
  const state = location.state;
  console.log("state from saveAs", state);

  const [isUnsaved, setIsUnsaved] = useState(false); // Track unsaved changes
  const [isLoadingSpinner, setIsLoading] = useState(false);
  const zoomLevel = useSelector((state) => state.bgUploads.zoomLevel);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { userDetails, templateNameOnChange } = useSelector(
    (state) => state?.auth
  );
  console.log("userDetails", userDetails, templateNameOnChange);

  const [data, setData] = useState([]);
  const { data: templateData, isLoading: isTemplateDataLoading } =
    useGetTemplateDataQuery();

  const [previewUploadImages, { isLoading: isPreviewLoading }] =
    usePreviewUploadImagesMutation();
  const [previewDefaultImages, { isLoading: isDefaultPreviewLoading }] =
    usePreviewUploadDefaultMutation();

  const [uploadTemplateImages, { isLoading: isLoadingImages }] =
    useUploadTemplateImagesMutation();

  const dispatch = useDispatch();

  const textData = useSelector((state) => state.textEditor);
  console.log("textData", textData);
  const lineData = useSelector((state) => state.draggableLine);
  const imageData = useSelector((state) => state.uploads);
  console.log("imageData", imageData);
  const backgroundImageData = useSelector((state) => state.bgUploads.image);
  console.log("backgroundImageData", backgroundImageData);
  const templateDimension = useSelector((state) => state.templateDimension);
  console.log("templateDimension", templateDimension);

  const [generateTemplate, { isLoading }] =
    useGenerateInstituteTemplateMutation();

  const [generateDefaultTemplate, { isLoading: isDefaultLoading }] =
    useGenerateTemplateMutation();
  const [updateDefaultTemplate, { isLoading: isUpdateLoader }] =
    useUpdateExistingTemplateMutation();

  console.log("lineData------", lineData);
  const getPreviewToBlob = useCallback(
    async (id, type) => {
      const bgImageUrl = backgroundImageData.url;
      if (bgImageUrl) await preloadImage(bgImageUrl);
      const previewSection = document.querySelector("#div1");
      console.log("previewSection", previewSection);

      const containerData = generateDOM([
        lineData,
        textData,
        imageData,
        backgroundImageData,
        {
          width: templateDimension?.width,
          height: templateDimension?.height,
          zoomLevel: templateDimension?.zoomLevel,
        },
        {
          type: "saveAs",
          details: userDetails,
        },
      ]);
      console.log("template data", containerData);
      const resultContainer = document.getElementById("template-result");
      resultContainer.innerHTML = "";
      resultContainer.append(containerData);

      if (resultContainer) {
        html2canvas(resultContainer, { scale: 2, useCORS: true }).then(
          (canvas) => {
            // Get the scaled canvas dimensions
            const scaledWidth = canvas.width;
            const scaledHeight = canvas.height;

            // Create a new canvas element to resize the image to original dimensions
            const resizedCanvas = document.createElement("canvas");
            const context = resizedCanvas.getContext("2d");

            // Set the resized canvas dimensions back to original container size
            resizedCanvas.width = templateDimension.width; // Original width of the certificate container
            resizedCanvas.height = templateDimension.height; // Original height of the certificate container

            // Draw the scaled canvas onto the resized canvas
            context.drawImage(
              canvas,
              0,
              0,
              scaledWidth,
              scaledHeight,
              0,
              0,
              templateDimension.width,
              templateDimension.height
            );

            // Convert the resized canvas to a downloadable image
            resizedCanvas.toBlob(async (blob) => {
              console.log("blob", blob);
              // Create a FormData object
              const formData = new FormData();

              // Append the blob (image) to the form data
              formData.append("file", blob, "certiificate-container.png");
              console.log("formData", formData);

              // return formData;
              console.log("type", type);
              try {
                const response =
                  type === "draft" || type === "edit" || type === "final"
                    ? await previewUploadImages({
                        formData,
                        id,
                      }).unwrap()
                    : await previewDefaultImages({ formData, id }).unwrap();

                console.log("Generated template response", response);
                if (response?.success) {
                  setIsModalVisible(false);
                }
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);

                console.error("Error generating template", error);
              }
            }, "image/png");
          }
        );
      } else {
        setIsLoading(false);
      }
    },
    [
      backgroundImageData,
      imageData,
      lineData,
      previewDefaultImages,
      previewUploadImages,
      templateDimension.height,
      templateDimension.width,
      templateDimension?.zoomLevel,
      textData,
      userDetails,
    ]
  );
  const generateTemplateHandler = useCallback(
    async (data, type) => {
      console.log("data frum handler", data);

      try {
        const response =
          type === "edit"
            ? await updateDefaultTemplate(data)?.unwrap()
            : type === "default"
            ? await generateDefaultTemplate(data)?.unwrap()
            : await generateTemplate(data).unwrap();
        console.log("Generated template response", response?.data?._id);
        if (response?.success) {
          toast.success(response?.message);

          dispatch(setTemplateName(templateNameOnChange));
        } else {
          setIsLoading(false);
        }
        getPreviewToBlob(
          response?.data?._id ?? templateDimension?.templateId,
          type
        );
      } catch (error) {
        setIsLoading(false);
        console.error("Error generating template", error);
      }
    },
    [
      dispatch,
      generateDefaultTemplate,
      generateTemplate,
      getPreviewToBlob,
      templateDimension?.templateId,
      templateNameOnChange,
      updateDefaultTemplate,
    ]
  );

  const uploadImagesHandler = useCallback(
    async (type) => {
      try {
        // CHECKING IF WE HAVE ITEM.FILE FOR IMAGES IF NOT THEN SETTING BLOB
        setIsLoading(true);
        const imageDataFile = imageData?.images
          ? await Promise.all(
              imageData.images.map(async (item) => {
                console.log("item from uploadImagesHandler", item);
                if (item.url) {
                  const response = await fetch(item.url);
                  const blob = await response.blob();
                  const file = new File([blob], "image.png", {
                    type: "image/png",
                  });
                  return {
                    file,
                    type: "regular",
                  };
                } else {
                  return {
                    file: item.file,
                    type: "regular",
                  };
                }
              })
            )
          : [];
        console.log("imageDataFile from loader", imageDataFile);

        if (backgroundImageData?.url && !backgroundImageData?.file) {
          const response = await fetch(backgroundImageData.url);
          const blob = await response.blob();
          const backgroundFile = new File([blob], "backgroundImage.png", {
            type: "image/png",
          });

          // Push backgroundFile to imageDataFile array with proper type
          imageDataFile.push({
            file: backgroundFile,
            type: "background",
          });
        }
        console.log("imageDataFile", imageDataFile);
        const uploadResponse = await uploadTemplateImages(
          imageDataFile
        ).unwrap();
        console.log("Upload response", uploadResponse);
        if (!uploadResponse) {
          setIsLoading(false);
        }

        let uploadIndex = 0;
        console.log("from uploader", imageData);

        const updatedImages = imageData?.images.map((item) => {
          console.log("item", item);

          console.log("got", item?.file);
          const newItem = {
            ...item,
            url: uploadResponse[uploadIndex],
            type: uploadResponse[uploadIndex]?.type,
          };
          uploadIndex++;
          return newItem;
        });

        console.log("Updated images with new URLs", updatedImages);

        // Find the background from the response (if applicable)
        const bg = uploadResponse.find((el) => el.type === "background");

        console.log("Background image", bg);

        // Set the updated images into the state or use them in the dataz
        const updatedData = [
          lineData,
          textData,
          { type: "images", images: updatedImages },
          bg || backgroundImageData,
          templateDimension,
        ];
        const payload = {
          updatedData,
          instituteId: userDetails?._id,
          isDraft: type === "draft" || type === "edit" ? true : false,
          name: templateNameOnChange,
          templateId: templateDimension?.templateId,
        };
        generateTemplateHandler(payload, type);
      } catch (error) {
        setIsLoading(false);
        console.error("Error uploading images", error);
      }
    },
    [
      backgroundImageData,
      generateTemplateHandler,
      imageData,
      lineData,
      templateDimension,
      templateNameOnChange,
      textData,
      uploadTemplateImages,
      userDetails?._id,
    ]
  );

  const dataHandler = useCallback(
    (type) => {
      console.log("Data is dataHandler");
      setIsUnsaved(false);

      setData([lineData, textData]);
      uploadImagesHandler(type);
      // getPreviewToBlob();
    },
    [lineData, textData, uploadImagesHandler]
  );

  useEffect(() => {
    setIsUnsaved(true);
  }, [textData, lineData, imageData, backgroundImageData]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isUnsaved) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Do you really want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isUnsaved]);

  // const imageDataFile =
  //   imageData?.images?.map((item) => ({
  //     file: item.file,
  //     type: "regular",
  //   })) || [];

  // if (backgroundImageData?.file) {
  //   imageDataFile.push({
  //     file: backgroundImageData.file,
  //     type: "background",
  //   });
  // }

  console.log("templateData", templateData?.data[0]?.templateJson);

  console.log("templateJson", templateData);

  useEffect(() => {
    if (templateData) {
      const templateJson = templateData?.data[0]?.templateJson;

      // Add lines if they exist
      if (templateJson?.[0]?.lines) {
        dispatch(setLines(templateJson[0].lines));
      }

      // Add text areas if they exist
      if (templateJson?.[1]?.textAreas) {
        dispatch(setTextAreas(templateJson?.[1]?.textAreas));
      }

      // Add images if they exist
      if (templateJson?.[2]?.images) {
        dispatch(addImages(templateJson?.[2].images));
        templateJson[2]?.images?.forEach((image) => {
          console.log("imagePosition", image);
          return dispatch(
            updateImageCoordinates({
              id: image.id,
              x: image?.x || 0,
              y: image?.y || 0,
            })
          );
        });
        templateJson[2]?.images?.forEach((image) => {
          console.log("imagePosition", image);
          return dispatch(
            updateImageDimensions({
              id: image.id,
              width: image?.width || 0,
              height: image?.height || 0,
            })
          );
        });
      }

      // Add background image if it exists

      if (templateJson?.[3]) {
        dispatch(setBgImage({ url: templateJson?.[3]?.url }));
      }

      if (templateJson?.[4]) {
        console.log("templateJson[4]", templateJson[4]);
        dispatch(
          setTemplateDimensions({
            width: templateJson[4]?.width,
            height: templateJson[4]?.height,
          })
        );
      }
    }
  }, [templateData, dispatch]);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <CommonButton
        type="primary"
        primary
        fontSize="16px"
        color="#FFF"
        width="100%"
        onClick={() => dataHandler("default")}
        loading={isDefaultLoading || isLoadingImages}
        style={{ fontSize: 16 }}
      >
        Save As Default
      </CommonButton>

      <CommonButton
        type="primary"
        primary
        fontSize="16px"
        color="#FFF"
        width="100%"
        onClick={() => {
          if (!image?.url) {
            toast.error("Please choose a background to proceed !");
            return;
          }
          setIsModalVisible(true);
        }}
        loading={isLoading || isLoadingImages}
        style={{ fontSize: 16 }}
      >
        Save As
      </CommonButton>

      <SaveModal
        templateSelected={templateDimension?.templateId && !state?.isDefault}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        dataHandler={dataHandler}
      />
      {isLoadingSpinner && <Spinner />}
    </div>
  );
};

export default SaveAs;
