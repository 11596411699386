import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import textEditorSlice from "./textEditorSlice";
import positionSlice from "./positionSlice";
import uploadSlice from "./ImageSlice";
import bgUploadSlice from "./BgSlice";
import draggableLineSlice from "./lineSlice";
import { Apis } from "../service/api";
import loadingSlice from "./loadingSlice";
import dimensionSlice from "./templateDimesnionSlice";
import authReducer from "../service/auth";
import { isTokenExpired } from "../helper/jwtHelper";
const rtkQueryErrorLogger = (api) => (next) => async (action) => {
  try {
    console.log("action.payload?.data?.msg ", action.payload?.data?.message);
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");

    if (isRejectedWithValue(action)) {
      if (
        action.payload?.data?.msg === "Missing Authorization Header" ||
        action.payload?.data?.message === "Invalid Token" ||
        action.payload?.data?.message === "Please provide token"
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("isLoggedIn");
        // window.location.href =
        //   process.env.REACT_APP_INSTITUTE_URL ||
        //   "https://stage-institute.certdigital.com";
      }
    }
  } catch (err) {
    /* empty */
  }

  return next(action);
};
export const store = configureStore({
  reducer: {
    [Apis.reducerPath]: Apis.reducer,
    textEditor: textEditorSlice,
    position: positionSlice,
    uploads: uploadSlice,
    bgUploads: bgUploadSlice,
    draggableLine: draggableLineSlice,
    templateDimension: dimensionSlice,
    auth: authReducer,

    loading: loadingSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(Apis.middleware).concat(rtkQueryErrorLogger),
});
