import { createSlice } from "@reduxjs/toolkit";
import { Apis } from "./api";

// Assuming userDetails is stored in localStorage with the userId
const userDetailsParsed = JSON.parse(localStorage.getItem("userDetails"));

const initialState = {
  token: localStorage.getItem("token") || null,
  isLoggedIn: localStorage.getItem("isLoggedIn") || null,
  templateNameOnChange: "" || userDetailsParsed?.templateName,
  userDetails: userDetailsParsed || null,
  userId: localStorage.getItem("userId") || null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setTemplateNameOnChange: (state, action) => {
      console.log("action.payload----- frim home", action.payload);
      state.templateNameOnChange = action.payload;
    },
    setTemplateName: (state, action) => {
      console.log("action.payload----- frim home", action.payload);
      if (state.userDetails) {
        state.userDetails.templateName = action.payload;
      } else {
        state.userDetails = { templateName: action.payload };
      }
      // Save updated userDetails to localStorage if needed
      localStorage.setItem("userDetails", JSON.stringify(state.userDetails));
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const {
  setToken,
  setIsLoggedIn,
  setUserDetails,
  setUserId,
  setTemplateName,
  setTemplateNameOnChange,
} = authSlice.actions;
export default authSlice.reducer;

export const authApi = Apis.injectEndpoints({
  endpoints: (builder) => ({
    generateOtp: builder.mutation({
      query: ({ userId, ...body }) => {
        if (!userId) {
          return; // Or handle differently
        }
        return {
          url: `/generate-otp/${userId}`,
          method: "POST",
          body,
        };
      },
    }),
    getUsersDetails: builder.query({
      query: (params) => ({
        url: `/institute-details`,
        method: "GET",
        params,
      }),
      providesTags: ["User"],
    }),
    validateOtp: builder.mutation({
      query: ({ payloadData }) => {
        console.log(payloadData, "----- body from payload"); // Log the body object before the request
        return {
          url: `/validate-otp/${payloadData?.userId}`,
          method: "POST",
          body: payloadData, // sending only body.payLoadData
        };
      },
    }),
    invalidatesTags: ["DraftTemplates"],

    overrideExisting: false,
  }),
});

export const {
  useGenerateOtpMutation,
  useValidateOtpMutation,
  useGetUsersDetailsQuery,
} = authApi;
