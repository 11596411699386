import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTemplateZoom } from "../store/templateDimesnionSlice";

export const useContainerDimensions = (myRef) => {
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const templateDimension = useSelector((state) => state.templateDimension);

  const zoomLevel = useSelector((state) => state.bgUploads.zoomLevel);
  console.log(
    "zoomLevel",
    (myRef.current?.offsetWidth - 2) * zoomLevel,
    zoomLevel,
    myRef.current?.offsetHeight - 2,
   
  );
  useEffect(() => {
    if (templateDimension?.width) {
      dispatch(setTemplateZoom(templateDimension.width / dimensions?.width));
    }
  }, [dimensions?.width, dispatch, templateDimension.width]);

  useEffect(() => {
    const updateDimensions = () => {
      if (myRef.current) {
        setDimensions({
          width: (myRef.current.offsetWidth - 2) * zoomLevel,
          height: (myRef.current.offsetHeight - 2) * zoomLevel,
        });
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions();
    });

    if (myRef.current) {
      resizeObserver.observe(myRef.current);
      // Set the initial dimensions
      updateDimensions();
    }

    return () => {
      if (myRef.current) {
        resizeObserver.unobserve(myRef.current);
      }
    };
  }, [myRef]);

  return dimensions;
};
