import styled from "styled-components";

export const CustomTableStyled = styled.div`
  .table-container {
    .ant-table-container {
      overflow-x: auto;
      box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
        rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
        rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;
    }
    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }
  }

  .ant-table-thead > tr > th {
    border-right: none !important;
    color: #777e91;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 102%; /* 16.32px */
    /* text-transform: uppercase; */
  }
  .ant-table-tbody {
    color: #3b4856;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: radial-gradient(circle, rgba(0, 102, 153, 0.4), transparent);
  }
  .ant-table-thead > tr > th:last-child {
    border-right: 2px solid rgba(159, 173, 189, 0.3) !important;
  }
  .ant-table-cell::before {
    /* background-color: transparent !important; */
  }
  .ant-table-cell {
    border-right: none !important;
    text-align: center;
    white-space: nowrap;
    background: rgba(255, 255, 255, 0.56);
  }
  .ant-table-cell:last-child {
    border-right: 2px solid rgba(159, 173, 189, 0.3) !important;
    text-align: end;
    display: ${(props) => props.staking && "flex"};
    justify-content: center;
  }
  .ant-table-content {
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
        rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
        rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
  }
  thead > tr {
    border-radius: 14px 14px 0px 0px;
    border: 2px solid rgba(159, 173, 189, 0.3);
    background: #fff;
  }
  .ant-pagination {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
`;
