// redux/slices/uploadSlice.js
import { createSlice } from "@reduxjs/toolkit";

const uploadBgSlice = createSlice({
  name: "Bguploads",
  initialState: {
    images: [],
    image: null,
    zoomLevel: 1,
  },
  reducers: {
    setBgImage: (state, action) => {
      state.image = action.payload;
    },
    removeBgImage: (state) => {
      state.image = null;
    },
    resetBgImage: (state) => {
      state.image = null;
    },
    setZoomLevel: (state, action) => {
      state.zoomLevel = action.payload;
    },
    setRestZoomLevel: (state) => {
      state.zoomLevel = 1;
    },
  },
});

export const {
  setBgImage,
  removeBgImage,
  resetBgImage,
  setZoomLevel,
  setRestZoomLevel,
} = uploadBgSlice.actions;
export default uploadBgSlice.reducer;
