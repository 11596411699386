import { useSelector } from "react-redux";
import { Apis } from "./api";

export const templateUpdateApi = Apis.injectEndpoints({
  endpoints: (builder) => ({
    // generateTemplate: builder.mutation({
    //   query: (body) => ({
    //     url: "/template-generate",
    //     method: "POST",
    //     body,
    //   }),
    // }),
    generateTemplate: builder.mutation({
      query: (body) => {
        console.log("templateDetails", body); // Logging templateDetails for debugging
        const data = body?.updatedData;

        return {
          url: "/template-generate", // Using the full or base URL
          method: "POST",
          body: {
            data,
          }, // Sending body as the request payload
        };
      },
    }),
    updateExistingTemplate: builder.mutation({
      query: (body) => {
        console.log("templateDetails", body); // Logging templateDetails for debugging
        const data = body?.updatedData;
        const templateId = body?.templateId;

        const name = body?.name;
        const isDraft = body?.isDraft;

        const baseUrl = "/institute-template-update-json";
        const fullUrl = templateId
          ? `${baseUrl}/${templateId}` // If templateId exists, append it to the URL
          : baseUrl; // If templateId is undefined or empty, just use the base URL

        return {
          url: fullUrl, // Using the full or base URL
          method: "PUT",
          body: {
            data,

            name: name,
            isDraft: isDraft,
          }, // Sending body as the request payload
        };
      },
    }),
    generateInstituteTemplate: builder.mutation({
      query: (body) => {
        console.log("templateDetails", body); // Logging templateDetails for debugging
        const data = body?.updatedData;
        const instituteId = body?.instituteId;

        const name = body?.name;
        const isDraft = body?.isDraft;

        const baseUrl = "/save-institute-template";
        const fullUrl = instituteId
          ? `${baseUrl}/${instituteId}` // If templateId exists, append it to the URL
          : baseUrl; // If templateId is undefined or empty, just use the base URL

        return {
          url: fullUrl, // Using the full or base URL
          method: "POST",
          body: {
            data,
            instituteId: instituteId,
            name: name,
            isDraft: isDraft,
          }, // Sending body as the request payload
        };
      },
    }),

    uploadTemplateImages: builder.mutation({
      query: (imageFile) => {
        const bodyFormData = new FormData();
        imageFile.forEach((imageFile) => {
          bodyFormData.append(
            imageFile.type === "regular" ? "images" : "background",
            imageFile?.file
          );
        });
        return {
          url: "/upload-multiple-images",
          method: "POST",
          body: bodyFormData,
        };
      },
    }),

    previewUploadImages: builder.mutation({
      query: ({ formData, id }) => {
        // const bodyFormData = new FormData();
        // bodyFormData.append("preview_image", imageFile);
        console.log("file-inapi", formData);
        return {
          url: `/institute-template-update-url/${id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Templates"],
    }),
    previewUploadDefault: builder.mutation({
      query: ({ formData, id }) => {
        // const bodyFormData = new FormData();
        // bodyFormData.append("preview_image", imageFile);
        console.log("file-inapi", formData);
        return {
          url: `/saveTemplateUrl/${id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Templates"],
    }),

    saveBackground: builder.mutation({
      query: (formData) => {
        // Confirm `formData` is a FormData instance and contains your file
        console.log("file-in-api-mutation:", formData.get("preview_image")); // for debugging

        return {
          url: `/save-institute-background`,
          method: "POST",
          body: formData,
          // No need to set `Content-Type`; the browser will automatically handle it for FormData
        };
      },
      invalidatesTags: ["Bgtemplates"],
    }),

    getTemplateData: builder.query({
      query: ({ _id }) => ({
        url: `/get-generated-template`,
        method: "GET",
        params: { _id },
      }),
    }),
    getTemplateBackground: builder.query({
      query: ({ _id }) => ({
        url: `/get-institute-background`,
        method: "GET",
        params: { _id },
      }),
      providesTags: ["Bgtemplates"],
    }),
    deleteTemplateBackground: builder.mutation({
      query: ({ _id }) => ({
        url: `/delete-institute-background/${_id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Bgtemplates"],
    }),

    getInstituteWiseData: builder.query({
      query: ({ _id }) => ({
        url: `/institute-templates`,
        method: "GET",
        params: { _id },
      }),
      providedTags: ["DraftTemplates"],
    }),
    getDefaultTemplateData: builder.query({
      query: ({ limit, skip }) => ({
        url: "/get-template-url",
        method: "GET",
        params: { limit, skip },
      }),
      providesTags: ["Templates","DraftTemplates"],
    }),
    getDraftTemplateData: builder.query({
      query: ({ limit, skip, isDraft }) => ({
        url: "/institute-templates",
        method: "GET",
        params: { limit, skip, isDraft },
      }),
      providesTags: ["Templates","DraftTemplates"],
    }),
  }),
});

export const {
  useGenerateTemplateMutation,
  useGenerateInstituteTemplateMutation,
  useUpdateExistingTemplateMutation,
  useGetTemplateDataQuery,
  useUploadTemplateImagesMutation,
  useGetDefaultTemplateDataQuery,
  usePreviewUploadImagesMutation,
  usePreviewUploadDefaultMutation,
  useGetDraftTemplateDataQuery,
  useGetInstituteWiseDataQuery,
  useGetTemplateBackgroundQuery,
  useSaveBackgroundMutation,
  useDeleteTemplateBackgroundMutation,
} = templateUpdateApi;
