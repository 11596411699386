import { useDispatch, useSelector } from "react-redux";
import { selectTextArea, setContent } from "../../../store/textEditorSlice";
import replaceDivsWithNewlines from "../../../helper/contentString";
import { Badge } from "antd";

const MandatoryTextAttributes = () => {
  const dispatch = useDispatch();
  const { textAreas, selectedId } = useSelector(
    (state) => state.textEditor
  );

  const selectedTextArea =
    textAreas.findIndex((textArea) => textArea.id === selectedId) || {};

  const handleContentChange = (e) => {
    dispatch(setContent({ id: selectedId, content: e.target.value }));
  };

  return (
    <div>
      <h3>Required Attributes</h3>

      <div className="text-queue-container">
        {textAreas
          ?.filter((item) => item.elementReadOnly) // Filter to include only items with elementReadOnly set to true
          .map((item, index) => (
            <div
              className="text-queue"
              key={item.id}
              style={{
                backgroundColor: "#f4f4f4",
              }}
              onClick={() =>
                dispatch(selectTextArea({ index: index, id: item?.id }))
              }
            >
              <Badge.Ribbon style={{ marginTop: -16 }} text="Required">
                <span style={{ fontFamily: `${item.fontFamily} ` }}>
                  {replaceDivsWithNewlines(item.content)}
                </span>
              </Badge.Ribbon>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MandatoryTextAttributes;
