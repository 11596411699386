// components/BgUpload.js
import React, { useCallback } from "react";
import { FileUploadWrapper } from "../../ImageUpload/styles";
import { UploadOutlined } from "@ant-design/icons";
import { useSaveBackgroundMutation } from "../../../service/templateUpdateApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../store/loadingSlice";
import { message } from "antd";

const BgUpload = ({ onImageUpload }) => {
  const [uploadBgImage, { isLoading }] = useSaveBackgroundMutation();

  const dispatch = useDispatch();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      // if (fileType === "image/jpeg") {
      //   toast.error("JPEG  files are not accepted.");
      //   return; // Exit the function if the file type is not allowed
      // }
      const isValidFileSize = file.size <= 1024 * 1024; // 1 MB limit

      console.log("file", file);
      // const imageUrl = URL.createObjectURL(file);
      if (isValidFileSize) {
        const formData = new FormData();
        formData.append("background", file);
        saveTemplateBg(formData, file);
      } else {
        if (!isValidFileSize) {
          message.error(`File ${file.name} exceeds the 1 MB size limit.`);
        }
      }

      // onImageUpload({ id: Date.now(), url: imageUrl, file });
    }
  };

  const saveTemplateBg = useCallback(
    async (formData, file) => {
      console.log("hi from saceTemplate");
      try {
        dispatch(setLoading(true));
        console.log("formData", formData);
        const response = await uploadBgImage(formData);

        console.log(
          "response",
          response,
          response?.data?.success,
          response?.error?.data.message
        );
        if (response?.data?.success) {
          const imageUrl = URL.createObjectURL(file);
          const { backgroundUrl, _id } = response?.data?.data;
          console.log("backgroundUrl", backgroundUrl, _id);
          onImageUpload({ id: _id, url: backgroundUrl, file });
          toast.success(response?.data?.message);
        }
        if (response?.error?.data) {
          console.log("works from here");
          toast.error(response?.error?.data?.message);
        }

        dispatch(setLoading(false));

        console.log("response", response);
      } catch (error) {
        console.log(error, "error------>");
        dispatch(setLoading(false));
      }
    },
    [dispatch, onImageUpload, uploadBgImage]
  );

  return (
    <FileUploadWrapper>
      <div className="file-upload">
        <input type="file" accept="image/*" onChange={handleFileChange} />
        <UploadOutlined />
        <div>Upload Template Bg</div>
      </div>
    </FileUploadWrapper>
  );
};

export default BgUpload;
