// components/DraggableImageContainer.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeImage, setSelectId } from "../../store/ImageSlice";
import DraggableImage from "./components/DraggableImage";
import { selectTextArea } from "../../store/textEditorSlice";
import { setSelectedLineId } from "../../store/lineSlice";
import { deleteduplicateElements } from "../../helper/removeDuplicates";

const DraggableImageContainer = () => {
  const images = useSelector((state) => state.uploads.images);
  console.log("images", images);
  const dispatch = useDispatch();

  const handleRemove = (id) => {
    dispatch(removeImage(id));
  };
  const handleChildClick = () => {
    //Making null the image or other selected elements
    dispatch(selectTextArea({ id: null, index: null }));
    dispatch(setSelectedLineId(null));
  };
  const deduplicatedData = deleteduplicateElements(images);

  return (
    <>
      {deduplicatedData.map((image) => (
        <DraggableImage
          key={image.id}
          id={image.id}
          url={image.url}
          defaultPath={image?.defaultPath}
          onRemove={handleRemove}
          onClick={handleChildClick}
          elementReadOnly={image?.elementReadOnly}
        />
      ))}
    </>
  );
};

export default DraggableImageContainer;
