import * as React from "react";
const PlusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#069"
      d="M29.558 0H10.442C4.674 0 0 4.674 0 10.442v19.116C0 35.326 4.674 40 10.442 40h19.116C35.326 40 40 35.326 40 29.558V10.442C40 4.674 35.326 0 29.558 0Zm-7.705 30.105A1.87 1.87 0 0 1 20 31.958c-.505 0-.968-.21-1.305-.547a1.847 1.847 0 0 1-.548-1.306v-8.252H9.895c-.506 0-.969-.21-1.306-.548A1.847 1.847 0 0 1 8.042 20a1.87 1.87 0 0 1 1.853-1.853h8.252V9.895A1.87 1.87 0 0 1 20 8.042a1.87 1.87 0 0 1 1.853 1.853v8.252h8.252A1.87 1.87 0 0 1 31.958 20a1.87 1.87 0 0 1-1.853 1.853h-8.252v8.252Z"
    />
  </svg>
);
export default PlusIcon;
