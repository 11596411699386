import { createSlice } from "@reduxjs/toolkit";

// Initial state for the template dimensions
const initialState = {
  width: 892, // Default width value, adjust as needed
  height: 591, // Default height value, adjust as needed
  zoomLevel: 1, // Default zoom level
  templateId: null || localStorage.getItem("templateId"), // Add a templateId to the state
};

const templateDimensionSlice = createSlice({
  name: "templateDimensions",
  initialState,
  reducers: {
    // Action to update both width and height
    setTemplateDimensions: (state, action) => {
      state.width = action.payload.width;
      state.height = action.payload.height;
    },
    // Action to update width only
    setTemplateWidth: (state, action) => {
      state.width = action.payload;
    },
    // Action to update height only
    setTemplateHeight: (state, action) => {
      state.height = action.payload;
    },
    // Action to update zoom level
    setTemplateZoom: (state, action) => {
      state.zoomLevel = action.payload;
    },
    // Action to set template ID
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
  },
});

export const {
  setTemplateDimensions,
  setTemplateWidth,
  setTemplateHeight,
  setTemplateZoom,
  setTemplateId,
} = templateDimensionSlice.actions;

export default templateDimensionSlice.reducer;
