import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { CONFIG } from "../config";

export const Apis = createApi({
  reducerPath: "apis",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_BASE_URL || "http://3.221.198.94:7000/gateway",
    // eslint-disable-next-line no-empty-pattern
    prepareHeaders: (headers, {}) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("authentication", `${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [],
  endpoints: () => ({}),
});
