import { useDispatch, useSelector } from "react-redux";
import {
  selectTextArea,
  setContent,
  toggleDefaultFieldEnabled,
} from "../../../store/textEditorSlice";
import replaceDivsWithNewlines from "../../../helper/contentString";
import { Badge, Switch, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getDynamicMessage } from "../../../constant/messages";

const DefaultAttributes = () => {
  const dispatch = useDispatch();
  const { textAreas, selectedIndex, selectedId } = useSelector(
    (state) => state.textEditor
  );

  const handleSwitchChange = (value, id) => {
    dispatch(toggleDefaultFieldEnabled({ id, value }));
    dispatch(selectTextArea({ id: id }));
  };

  return (
    <div>
      <h3>
        Default Attributes{" "}
        <span>
          <Tooltip
            placement="bottom"
            title={getDynamicMessage("Default Attribute")}
          >
            <InfoCircleOutlined color="black" width={40} height={40} />
          </Tooltip>
        </span>
      </h3>

      <div className="text-queue-container">
        {textAreas
          ?.filter((item) => item.defaultField) // Filter to include only items with elementReadOnly set to true
          .map((item, index) => (
            <div
              className="text-queue"
              key={item.id}
              style={{
                backgroundColor: "#f4f4f4",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() =>
                dispatch(selectTextArea({ index: index, id: item?.id }))
              }
            >
              {/* <Badge.Ribbon style={{ marginTop: -16 }} text="default"> */}
              <span style={{ fontFamily: `${item.fontFamily} ` }}>
                {replaceDivsWithNewlines(item.content)}
              </span>
              {/* </Badge.Ribbon> */}
              <Switch
                onChange={(value) => handleSwitchChange(value, item?.id)}
                checked={item?.enabled}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default DefaultAttributes;
