import { DeleteOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Row, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteTemplateBackgroundMutation,
  useGetTemplateBackgroundQuery,
} from "../../../service/templateUpdateApi";
import { resetBgImage, setBgImage } from "../../../store/BgSlice";
import { BgSkeletonWrapper } from "../../../pages/Home/style";
import { useCallback } from "react";
import { toast } from "react-toastify";

const BgGrid = () => {
  const { data, isLoading } = useGetTemplateBackgroundQuery({});
  const [deleteTemplateBackground, { isLoading: deleteLoader }] =
    useDeleteTemplateBackgroundMutation();
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    async (id) => {
      try {
        let res = await deleteTemplateBackground({ _id: id });
        console.log("res", res);
        if (res?.data?.success) {
          toast?.success(res?.data?.message);
          dispatch(resetBgImage());
        } else if (res?.error) {
          toast?.error(res?.error?.data?.message);
        }
        console.log("Template background deleted:", id);
      } catch (error) {
        console.error("Failed to delete template background:", error);
      }
    },
    [deleteTemplateBackground, dispatch]
  );
  console.log("data", data);

  const handleClick = (item) => {
    console.log("item", item);
    const newImage = { id: item?._id, url: item?.backgroundUrl };
    dispatch(setBgImage(newImage));
  };


  return (
    <div
      style={{ maxHeight: "70vh ", cursor: "pointer" }}
      className="image-grid"
    >
      <Row wrap={true}>
        {isLoading || deleteLoader
          ? Array.from({ length: 9 }).map((_, index) => (
              <Col gap={10} md={24} lg={12}>
                <BgSkeletonWrapper key={index}>
                  <Skeleton.Image
                    style={{ width: "100%", height: 120 }} // Adjust size as needed
                    active
                  />
                  <Skeleton.Input
                    style={{ width: 100, marginTop: 8 }}
                    active
                    size="small"
                  />
                </BgSkeletonWrapper>
              </Col>
            ))
          : data?.data?.map((image, index) => (
              <Col gap={10} md={24} lg={12}>
                <div key={image._id} className="image-item">
                  {image?.elementReadOnly ? (
                    <Badge.Ribbon
                      text="Default"
                      color="blue"
                      Badge
                      style={{ marginTop: -15 }}
                    >
                      <img src={image.url} alt="" />
                    </Badge.Ribbon>
                  ) : (
                    <img
                      onClick={() => handleClick(image)}
                      src={image.backgroundUrl}
                      alt=""
                    />
                  )}

                  {image?.elementReadOnly ? (
                    ""
                  ) : (
                    <Button onClick={() => handleDelete(image?._id)}>
                      <DeleteOutlined />
                    </Button>
                  )}
                </div>
              </Col>
            ))}
      </Row>
    </div>
  );
};

export default BgGrid;
