// components/ChangeBackground.js
import { useDispatch, useSelector } from "react-redux";

import { StyledImageWrapper } from "../ImageUpload/styles";

import styled from "styled-components";
import BgUpload from "./components/BgUpload";
import { removeBgImage, setBgImage } from "../../store/BgSlice";
import ImageGrid from "../ImageUpload/ImageGrid";
import BgGrid from "./components/BgGrid";

const PreviewImage = styled.div`
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: ${(props) => `url(${props.bgImageUrl})`};
`;

export const ChangeBackground = () => {
  const dispatch = useDispatch();
  const image = useSelector((state) => state.bgUploads.image); // Access the uploaded image

  const handleImageUpload = (newImage) => {
    console.log("newImage", newImage);
    dispatch(setBgImage(newImage));
  };

  const handleRemoveImage = () => {
    dispatch(removeBgImage());
  };

  return (
    <StyledImageWrapper>
      <h3>Upload Background</h3>
      <BgUpload onImageUpload={handleImageUpload} />
      <h3>Select Background</h3>
      <BgGrid />
    </StyledImageWrapper>
  );
};
