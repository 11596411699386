import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DraggableResizable from "../Dragable/DraggableResizableText";
import DraggableImageContainer from "../Dragable/DraggableImageContainer";
import TextToolbar from "../textEditor/TextEdiitor";
import { StyledCertificateContainer, StyledTemplateWrapper } from "./styles";
import DraggableLine from "../Dragable/DraggableLine";
import ToolBarTabs from "../ToolBarTabs";
import { selectTextArea } from "../../store/textEditorSlice";
import { setSelectId } from "../../store/ImageSlice";
import ZoomLevelUI from "../ZoomLevelUI/ZoomLevelUI";
import { useContainerDimensions } from "../../hooks/getDimensiuons";
import { Button, Drawer } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import useTemplateData from "../../hooks/setTemplateData";
import { useLocation } from "react-router-dom";

export const TemplateLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();



  const templateDimension = useSelector((state) => state.templateDimension);
  const zoomLevel = useSelector((state) => state.bgUploads.zoomLevel);
  console.log("zoomLevel from layout ", zoomLevel);
  const image = useSelector((state) => state.bgUploads.image);
  const lines = useSelector((state) => state.draggableLine.lines);
  const isLoading = useSelector((state) => state.loading.loading);
  const [marginLeft, setMarginLeft] = useState(0);
  const state = location.state;

  const { isLoading: templateLoader, resetState } = useTemplateData(
    templateDimension?.templateId,
    state?.from === "editDraft" ? 2 : 1
  );
  console.log('state?.from', state)

  const templateRef = useRef(null);
  const containerRef = useRef(null);
  const { width: containerWidth, height: containerHeight } =
    useContainerDimensions(templateRef);
  const { width: parentContainerWidth, height: parentContainerHeight } =
    useContainerDimensions(containerRef);
  console.log(
    "parentContainerHeight",
    parentContainerHeight,
    parentContainerWidth
  );
  const [scale, setScale] = useState(1); // State to store the calculated scale
useEffect(() => {
  if (templateDimension.width && templateDimension.height) {
    // Calculate scale to fit the template within the available container space
    const scaleWidth = containerWidth / templateDimension.width;
    const scaleHeight = containerHeight / templateDimension.height;

    const calculatedScale = Math.min(scaleWidth, scaleHeight, 1); // Use the minimum scale factor
    setScale(calculatedScale);

    // Adjust for the combined effect of zoom and scale
    const scaledTemplateWidth = templateDimension.width * calculatedScale;
    const scaledTemplateHeight = templateDimension.height * calculatedScale;

    // Calculate marginLeft for horizontal centering
    const marginLeft =
      parentContainerWidth > scaledTemplateWidth * zoomLevel
        ? (parentContainerWidth - scaledTemplateWidth * zoomLevel) / 2
        : 0;

    setMarginLeft(marginLeft);

    console.log("Calculated Scale:", calculatedScale);
    console.log("Scaled Template Width:", scaledTemplateWidth);
    console.log("Scaled Template Height:", scaledTemplateHeight);
    console.log("Calculated Margin Left:", marginLeft);
  }
}, [
  templateDimension,
  containerWidth,
  containerHeight,
  parentContainerWidth,
  zoomLevel,
]);

  const handleChildClick = () => {
    dispatch(selectTextArea({ id: null, index: null }));
    dispatch(setSelectId(null));
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);


  return (
    <>
      <StyledTemplateWrapper bgImageUrl={image?.url}>
        <div className="tool-bar-container">
          <ToolBarTabs />
        </div>
        <div className="drawer-btn">
          <Button type="primary" onClick={showDrawer}>
            <MenuUnfoldOutlined />
          </Button>
        </div>
        <div className="tabs-drawer">
          <Drawer
            getContainer={false}
            onClose={onClose}
            placement="left"
            open={open}
          >
            <ToolBarTabs />
          </Drawer>
        </div>
        <div className="preview-section">
          <TextToolbar />
          <ZoomLevelUI />
          <div
            id="template-result"
            style={{
              position: "absolute",
              // right: templateDimension?.width
              //   ? `-${templateDimension.width}px`
              //   : "-100%",
              // top: templateDimension?.height
              //   ? `-${templateDimension.height}px`
              //   : "-100%",
              right: "-1000%",

              top: "-1000%",
            }}
          ></div>

          <div ref={containerRef} className="cert-handle">
            <StyledCertificateContainer
              width={templateDimension?.width}
              height={templateDimension?.height}
              ref={templateRef}
              bgImageUrl={image?.url}
              id="div1"
              style={{
                transform: `scale(${scale * zoomLevel})`, // Apply combined scale and zoom level

                transformOrigin: "top left",
                transition: "transform 0.2s ease", // Smooth transition for zoom and scaling
                marginLeft: `${marginLeft}px`,
              }}
            >
              <>
                <DraggableResizable renderedDimensions={containerWidth} />
                <DraggableImageContainer />
                {lines.map((line) => (
                  <DraggableLine
                    onClick={handleChildClick}
                    key={line.id}
                    line={line}
                  />
                ))}
              </>
            </StyledCertificateContainer>
          </div>
        </div>
      </StyledTemplateWrapper>
    </>
  );
};
