import { Tabs } from "antd";
import React from "react";
import TextIcon from "../../assets/SvgIcons/TextIcon";
import ElementIcon from "../../assets/SvgIcons/ElementIcon";
import { ToolBarTabsStyled } from "./style";
import TextAreaComponent from "./TextArea";
import { ChangeBackground } from "../ChangeBackground/ChangeBg";
import ElementsTab from "../ElementsTab";
import BackgroundIcon from "../../assets/SvgIcons/BackgroundIcon";
import { setActiveTab } from "../../store/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import AttributeIcon from "../../assets/SvgIcons/attribute";
import { Attribute } from "../Attributes/Attribute";

const ToolBarTabs = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.loading.activeTab);

  const handleTabChange = (key) => {
    dispatch(setActiveTab(key));
  };
  const tabItems = [
    {
      label: "Text",
      key: "1",
      content: <TextAreaComponent />,
      icon: <TextIcon />,
      
    },
    {
      label: "Elements",
      key: "3",
      content: <ElementsTab />,
      icon: <ElementIcon />,
    },
    {
      label: "Background",
      key: "2",
      content: <ChangeBackground />,
      icon: <BackgroundIcon />,
    },
    // {
    //   label: "Templates",
    //   key: "5",
    //   content: <DefaultTemplates />,
    //   icon: <FileImageOutlined style={{ fontSize: "40px" }} />,
    // },
    {
      label: "Attribute",
      key: "7",
      content: <Attribute />,
      icon: <AttributeIcon/>,
    },
  ];

  return (
    <ToolBarTabsStyled>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        tabPosition={"left"}
        items={tabItems.map((tab) => ({
          label: (
            <div className="tab-icon">
              {tab.icon} {tab.label}
            </div>
          ),
          key: tab.key,
          children: tab.content,
        }))}
      />
    </ToolBarTabsStyled>
  );
};

export default ToolBarTabs;
