import React, { useRef, useEffect, useState } from "react";
import interact from "interactjs";
import { useDispatch, useSelector } from "react-redux";
import {
  replaceImage,
  setSelectId,
  updateImageCoordinates,
  updateImageDimensions,
} from "../../../store/ImageSlice";
import { Button, message, Tooltip, Upload } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { ResizeHandle } from "../../../style/globalStyle";

import { setLoading } from "../../../store/loadingSlice";

// Styled resize handles

const DraggableImage = ({
  id,
  url,
  onRemove,
  onClick,
  elementReadOnly,
  defaultPath,
}) => {
  const [isImageloaded, setIsImageLoaded] = useState(true);
  const [isHovered, setisHovered] = useState(null);

  const selectedId = useSelector((state) => state.uploads.selectedId);
  const isLoading = useSelector((state) => state.loading.loading);
  console.log("url", url);

  const {
    x,
    y,
    width: imageUpdatedWidth,
    height: imageUpdatedHeight,
  } = useSelector(
    (state) =>
      state.uploads.images.find((img) => img.id === id) || { x: 0, y: 0 }
  );

  const imageRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.style.transform = `translate(${x}px, ${y}px)`;
      imageRef.current.setAttribute("data-x-image", x);
      imageRef.current.setAttribute("data-y-image", y);
      imageRef.current.style.width = `${imageUpdatedWidth}px`;
      imageRef.current.style.height = `${imageUpdatedHeight}px`;
    }
  }, [x, y]);

  useEffect(() => {
    // Interact.js setup for draggable
    const parentElement = imageRef.current.parentElement;

    interact(imageRef.current)
      .draggable({
        modifiers: [
          interact.modifiers.restrict({
            restriction: parentElement,
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            endOnly: true,
          }),
        ],
        listeners: {
          move(event) {
            const { target } = event;
            const x =
              (parseFloat(target.getAttribute("data-x-image")) || 0) + event.dx;
            const y =
              (parseFloat(target.getAttribute("data-y-image")) || 0) + event.dy;

            // Update the element's transform and data-x-image, data-y-image attributes
            target.style.transform = `translate(${x}px, ${y}px)`;
            target.setAttribute("data-x-image", x);
            target.setAttribute("data-y-image", y);

            // Dispatch new coordinates to Redux
            dispatch(updateImageCoordinates({ id, x, y }));
          },
        },
      })
      .resizable({
        edges: {
          left: true,
          right: true,
          bottom: true,
          top: true,
        }, // Enable resizing from edges and corners
        listeners: {
          move(event) {
            const { target } = event;

            // Get the original coordinates
            let x = parseFloat(target.getAttribute("data-x-image")) || 0;
            let y = parseFloat(target.getAttribute("data-y-image")) || 0;

            const { width, height } = event.rect;

            // Define variables for fixing the opposite edge
            const edges = event.edges;

            // Determine which edge is being dragged and fix the opposite edge
            if (edges.left) {
              // Fix right side (x-coordinate remains the same)
              x += event.deltaRect.left;
            } else if (edges.right) {
              // Fix left side (no change in x)
            }

            if (edges.top) {
              // Fix bottom side (y-coordinate remains the same)
              y += event.deltaRect.top;
            } else if (edges.bottom) {
              // Fix top side (no change in y)
            }

            // Set the new width and height of the image
            target.style.width = `${width}px`;
            target.style.height = `${height}px`;

            // Reapply translation to the image
            target.style.transform = `translate(${x}px, ${y}px)`;
            target.setAttribute("data-x-image", x);
            target.setAttribute("data-y-image", y);

            // Dispatch updated coordinates and dimensions to Redux
            dispatch(updateImageCoordinates({ id, x, y }));
            dispatch(updateImageDimensions({ id, width, height }));
          },
        },
        modifiers: [
          interact.modifiers.restrictSize({
            min: { width: 50, height: 50 }, // Minimum size of the image
          }),
        ],
      });

    // Keydown event handler for arrow keys
    const handleKeyDown = (event) => {
      if (selectedId === id) {
        const step = 10; // Amount to move with each key press
        const target = imageRef.current;
        let x = parseFloat(target.getAttribute("data-x-image")) || 0;
        let y = parseFloat(target.getAttribute("data-y-image")) || 0;

        switch (event.key) {
          case "ArrowUp":
            y -= step;
            break;
          case "ArrowDown":
            y += step;
            break;
          case "ArrowLeft":
            x -= step;
            break;
          case "ArrowRight":
            x += step;
            break;
          default:
            return; // Ignore other keys
        }

        event.preventDefault();

        // Update the element's position
        target.style.transform = `translate(${x}px, ${y}px)`;
        target.setAttribute("data-x-image", x);
        target.setAttribute("data-y-image", y);

        // Dispatch new coordinates to Redux
        dispatch(updateImageCoordinates({ id, x, y }));
      }
    };

    if (selectedId === id) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch, id, selectedId]);

  const handleImageUpload = ({ file }) => {
    console.log("file", file);
    const isValidFileType =
      file.type === "image/png" || file.type === "image/jpeg";
    const isValidFileSize = file.size <= 1024 * 1024; // 1 MB limit

    if (isValidFileType && isValidFileSize) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newUrl = e.target.result;
        console.log("newUrl", newUrl);
        dispatch(replaceImage({ id, url: newUrl }));
      };
      reader.readAsDataURL(file);
    } else {
      if (!isValidFileType) {
        message.error(`File ${file.name} is not a valid PNG or JPEG image.`);
      } else if (!isValidFileSize) {
        message.error(`File ${file.name} exceeds the 1 MB size limit.`);
      }
    }
  };
  return (
    <div
      ref={imageRef}
      className="draggable-image"
      data-id={id}
      style={{
        position: "absolute",
        width: "150px",
        height: "150px",
        zIndex: 20000,

        border:
          isHovered === id
            ? "2px solid blue"
            : selectedId === id
            ? "2px solid blue"
            : null, // Blue border on hover
      }}
      tabIndex={0} // Make the image focusable
      onMouseEnter={() => setisHovered(id)} // Set hovered index
      onMouseLeave={() => setisHovered(null)} // Reset hover on
      onClick={() => {
        dispatch(setSelectId(id));
        onClick();
      }}
    >
      <img
        onLoad={() => {
          setIsImageLoaded(true);
          dispatch(setLoading(false)); // Dispatch setLoading(false) once the image is loaded
        }}
        onError={() => {
          setIsImageLoaded(false);
          dispatch(setLoading(false)); // Dispatch setLoading(false) if image fails to load
        }}
        src={isImageloaded ? url : defaultPath}
        alt=""
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      {/* {/ Resize handles for all edges /} */}
      {selectedId === id && (
        <>
          {elementReadOnly ? (
            <>
              <Tooltip
                placement="bottom"
                title={`Please click to replace ${
                  id === "default-image" ? "logo" : "signature"
                } !`}
              >
                <Upload
                  showUploadList={false}
                  beforeUpload={(file) => {
                    handleImageUpload({ file });
                    return false;
                  }}
                >
                  <Button icon={<UploadOutlined />} />
                </Upload>
              </Tooltip>
            </>
          ) : (
            <Button
              className="remove-button image-tbn"
              onClick={() => onRemove(id)}
              style={{
                position: "absolute",
                right: -5,
                width: 10,
                borderRadius: 20,
                insetInlineEnd: "-37px",
                insetBlockEnd: "-6px",
              }}
            >
              <DeleteOutlined />
            </Button>
          )}

          <ResizeHandle
            style={{
              left: -6,
              top: 0,
              cursor: "nwse-resize",
              transform: "translateY(-50%)",
            }}
          />
          <ResizeHandle
            style={{
              left: -6,
              right: 0,
              cursor: "nesw-resize",
              transform: "translateY(-50%)",
            }}
          />
          <ResizeHandle
            style={{
              top: -6,
              right: -10,
              cursor: "nesw-resize",
              transform: "translateX(-50%)",
            }}
          />
          <ResizeHandle
            style={{
              bottom: -6,
              right: -10,
              cursor: "nwse-resize",
              transform: "translateX(-50%)",
            }}
          />
        </>
      )}
    </div>
  );
};

export default DraggableImage;
