import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStyle, toggleStyle } from "../../store/textEditorSlice";
import { TextEditorStyled, Toolbar } from "./style";
import { Button, Popover, Radio, Select, Slider } from "antd";
import TextAlignLeftIcon from "../../assets/SvgIcons/TextAlignLeftIcon";
import TextAlignCenterIcon from "../../assets/SvgIcons/TextAlignCenterIcon";
import TextAlignRightIcon from "../../assets/SvgIcons/TextAlignRightIcon";
import TextAlignJustifyIcon from "../../assets/SvgIcons/TextAlignJustifyIcon";
import SaveAs from "../SaveAs/SaveAs";
import DownloadTemplate from "../DownloadTemplate/DownloadTemplate";

const TextToolbar = () => {
  const dispatch = useDispatch();
  const { textAreas, selectedId } = useSelector((state) => state.textEditor);
  function getIndexById(id) {
    const index = textAreas.findIndex((textArea) => textArea.id === id);
    return index; // The index will be 0 for the first element, or -1 if not found
  }

  const selectedTextAreaIndex = getIndexById(selectedId);

  const selectedTextArea = textAreas[selectedTextAreaIndex] || {};

  const handleSelectChange = (key, value) => {
    dispatch(setStyle({ id: selectedId, key, value }));
  };

  const optionOfFont = [
    { value: "Arial, sans-serif", label: "Arial" },
    { value: "Courier New, monospace", label: "Courier New" },
    { value: "'Bokor', cursive", label: "Bokor" },
    { value: "Georgia, serif", label: "Georgia" },
    { value: "Times New Roman, serif", label: "Times New Roman" },
    { value: "Verdana, sans-serif", label: "Verdana" },
    { value: "Beau Rivage, cursive", label: "Beau Rivage" },
    { value: "Roboto, sans-serif", label: "Roboto" },
    { value: "Lato, sans-serif", label: "Lato" },
    { value: "Oswald, sans-serif", label: "Oswald" },
    { value: "Crimson Pro, serif", label: "Crimson Pro" },
    { value: "Merriweather, serif", label: "Merriweather" },
    { value: "Tangerine, cursive", label: "Tangerine" },
    { value: "EB Garamond, serif", label: "EB Garamond" },
    { value: "'Abhaya Libre', serif", label: "Abhaya Libre" },
    { value: "'Montserrat', sans-serif", label: "Montserrat" },
    { value: "'Poppins', sans-serif", label: "Poppins" },
    { value: "'Open Sans', sans-serif", label: "Open Sans" },
    { value: "'Raleway', sans-serif", label: "Raleway" },
    { value: "'Source Sans Pro', sans-serif", label: "Source Sans Pro" },
    { value: "'Libre Baskerville', serif", label: "Libre Baskerville" },
    { value: "'Alex Brush', cursive", label: "Alex Brush" },
    { value: "'Rubik', sans-serif", label: "Rubik" },
  ];

  const options = [
    { label: <TextAlignLeftIcon />, value: "left" },
    { label: <TextAlignCenterIcon />, value: "center" },
    { label: <TextAlignRightIcon />, value: "right" },
    { label: <TextAlignJustifyIcon />, value: "justify" },
  ];

  return (
    <TextEditorStyled>
      <Toolbar>
        <div className="style-btn">
          <Select
            showSearch
            style={{
              width: 200,
            }}
            disabled={selectedTextArea === null}
            placeholder="Search to Select"
            optionFilterProp="label"
            value={selectedTextArea.fontFamily}
            onChange={(value) => handleSelectChange("fontFamily", value)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={optionOfFont}
          />

          <Popover
            placement="top"
            getPopupContainer={(trigger) => trigger.parentElement}
            content={
              <div className={`font-size-slider ${true ? "show" : ""}`}>
                <Slider
                  min={12}
                  max={100}
                  step={2}
                  value={parseInt(selectedTextArea.fontSize, 10) || 16}
                  onChange={(value) =>
                    handleSelectChange("fontSize", `${value}px`)
                  }
                  tooltip={{ formatter: (value) => `${value}px` }}
                  className="slider-font-size"
                  disabled={selectedTextArea === null}
                />
              </div>
            }
          >
            <Button>{selectedTextArea.fontSize}</Button>
          </Popover>

          <Radio.Group
            options={options}
            onChange={(e) => handleSelectChange("textAlign", e.target.value)}
            value={selectedTextArea.textAlign} // Current value from state
            optionType="button" // Display as buttons
            buttonStyle="solid" // Solid style for buttons
            disabled={selectedTextArea === null}
          />

          <input
            className="color-picker"
            type="color"
            value={selectedTextArea.fontColor}
            disabled={selectedTextArea === null}
            onChange={(e) => handleSelectChange("fontColor", e.target.value)}
          />

          <Button
            active={selectedTextArea.isBold}
            onClick={() =>
              dispatch(toggleStyle({ id: selectedId, key: "isBold" }))
            }
            disabled={selectedTextArea === null}
            className="bold-btn"
          >
            B
          </Button>
          <Button
            active={selectedTextArea.isItalic}
            disabled={selectedTextArea === null}
            onClick={() =>
              dispatch(toggleStyle({ id: selectedId, key: "isItalic" }))
            }
            className="Italic-btn"
          >
            I
          </Button>
          <br />
          <Button
            active={selectedTextArea.isUnderline}
            disabled={selectedTextArea === null}
            onClick={() =>
              dispatch(toggleStyle({ id: selectedId, key: "isUnderline" }))
            }
            className="underline-btn"
          >
            U
          </Button>
        </div>
      </Toolbar>
      <div className="save-btn">
        {/* <SaveAs /> */}
        <DownloadTemplate />
      </div>
    </TextEditorStyled>
  );
};

export default TextToolbar;
