import { useDispatch, useSelector } from "react-redux";
import {
  addTextArea,
  removeTextArea,
  selectTextArea,
} from "../../store/textEditorSlice";
import { Button } from "antd";

export const AddText = () => {
  const { selectedIndex, selectedId } = useSelector(
    (state) => state.textEditor
  );
  console.log("selectedIndex from add text ", selectedId, selectedIndex);

  const dispatch = useDispatch();
  return (
    <div className="add-text-btn">
      <Button onClick={() => dispatch(addTextArea())}> + Add Text</Button>
      {/* <button
        onClick={() => {
          dispatch(removeTextArea(selectedId));
          dispatch(selectTextArea({ id: null, index: null }));
        }}
      >
        Remove Text{" "}
      </button> */}
    </div>
  );
};
