import { Form, Input } from "antd";
import { CommonButton } from "../../../style/globalStyle";
import { useEffect } from "react";
import {
  setTemplateName,
  setTemplateNameOnChange,
} from "../../../service/auth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../../helper/substring";

export const SaveTemplateNameForm = () => {
  const templateName = useSelector(
    (state) => state?.auth?.templateNameOnChange
  );
  console.log("templateName", templateName);

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  // Set the initial value for the template name field
  useEffect(() => {
    form.setFieldsValue({ template_name: capitalizeFirstLetter(templateName) });
  }, [templateName, form]);

  const onFinish = (values) => {
    console.log("Form Values:", values);
    // dispatch(setTemplateName(values?.template_name));
    // toast.success("Template name Updated !");
  };

  return (
    <Form
      form={form}
      requiredMark={false}
      layout="horizontal"
      onFinish={onFinish}
    >
      <Form.Item
        name="template_name"
        className="center-error-message"
        rules={[{ required: true, message: "Please enter a template name!" }]}
      >
        <Input
          onChange={(e) => {
            // Automatically remove leading whitespace
            form.setFieldsValue({
              template_name: e.target.value.replace(/^\s+/, ""),
            });
            const value = e.target.value.replace(/^\s+/, "");
            dispatch(setTemplateNameOnChange(value));
          }}
          style={{ height: "50px", fontWeight: "bold", borderRadius: "20px" }}
        />
      </Form.Item>
      <Form.Item
        style={{
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        {/* <CommonButton
          htmlType="submit"
          fontSize="16px"
          primary
          color="#FFF"
          width="100%"
        >
          Edit Template Name
        </CommonButton> */}
      </Form.Item>
    </Form>
  );
};
