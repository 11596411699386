import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setZoomLevel } from "../../store/BgSlice";
import { CommonButton } from "../../style/globalStyle";

const ZoomLevelUI = () => {
  const zoomLevel = useSelector((state) => state.bgUploads.zoomLevel);
  const dispatch = useDispatch();

  const handleZoomChange = (newZoomLevel) => {
    if (newZoomLevel >= 0.1 && newZoomLevel <= 1.5) { // Set minimum to 0.1 for 10%
      console.log('newZoomLevel', newZoomLevel);
      dispatch(setZoomLevel(newZoomLevel));
    }
  };

  return (
    <div className="zoom-level-container">
      <CommonButton
        onClick={() => handleZoomChange(zoomLevel - 0.05)}
        htmlType="button"
        fontSize="20px"
        bordered
        color="#000"
        width="40px"
        style={{ marginRight: 20 }}
        disabled={zoomLevel <= 0.1} // Update to match new minimum zoom level
      >
        -
      </CommonButton>
      <div className="zoom-input">
        <span>{Math.round(zoomLevel * 100)}%</span>
      </div>
      <CommonButton
        onClick={() => handleZoomChange(zoomLevel + 0.05)}
        htmlType="button"
        fontSize="20px"
        bordered
        color="#000"
        width="40px"
        style={{ marginLeft: 20 }}
        disabled={zoomLevel >= 1.5}
      >
        +
      </CommonButton>
    </div>
  );
};

export default ZoomLevelUI;
