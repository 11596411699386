import FileUpload from "./FileUpload";
import ImageGrid from "./ImageGrid";
import { StyledImageWrapper } from "./styles";

const ImageToolBar = () => {
  return (
    <StyledImageWrapper>
      <h3>Images </h3>
      <ImageGrid />
      <FileUpload />
      <div className="sub-heading">
        <span>
          We accept JPG and PNG files. The maximum image size should be 1 MB.
        </span>
      </div>
    </StyledImageWrapper>
  );
};

export default ImageToolBar;
