import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTextArea, setContent } from "../../store/textEditorSlice";
import { AddText } from "../AddText/AddText";
import { TextArea } from "../../style/globalStyle";
import { Badge } from "antd";
import replaceDivsWithNewlines from "../../helper/contentString";
import { subStringForTextArea } from "../../helper/substring";

const TextAreaComponent = () => {
  const dispatch = useDispatch();
  const { textAreas, selectedId } = useSelector((state) => state.textEditor);

  const selectedTextArea =
    textAreas.findIndex((textArea) => textArea.id === selectedId) || {};
  console.log("selectedTextArea", selectedTextArea);

  const handleContentChange = (e) => {
    if (!selectedId?.startsWith("default")) {
      dispatch(setContent({ id: selectedId, content: e.target.value }));
    }
  };

  return (
    <div>
      <h3>Texts</h3>
      <AddText />
      <TextArea
        fontFamily={selectedTextArea.fontFamily}
        fontSize={selectedTextArea.fontSize}
        isBold={selectedTextArea.isBold}
        isItalic={selectedTextArea.isItalic}
        isUnderline={selectedTextArea.isUnderline}
        fontColor={selectedTextArea.fontColor}
        textAlign={selectedTextArea.textAlign}
        value={replaceDivsWithNewlines(selectedTextArea.content)}
        onChange={handleContentChange}
        placeholder="Type your text here..."
        style={{ whiteSpace: "pre-wrap" }}
      />

      <div className="text-queue-container">
        {textAreas
          .filter(
            (textArea) =>
              !textArea.defaultField && !textArea.customField
          )
          .map((item, index) => {
            return (
              <div
                className="text-queue"
                key={item.id}
                style={{
                  backgroundColor: item.elementReadOnly && "#f4f4f4",
                }}
                onClick={() =>
                  dispatch(selectTextArea({ index: index, id: item?.id }))
                }
              >
                {item?.elementReadOnly ? (
                  <Badge.Ribbon style={{ marginTop: -16 }} text="Default">
                    <span style={{ fontFamily: `${item.fontFamily} ` }}>
                      {replaceDivsWithNewlines(item.content)}
                    </span>
                  </Badge.Ribbon>
                ) : (
                  <span
                    style={{ fontFamily: `${item.fontFamily} `, fontSize: 18 }}
                  >
                    {subStringForTextArea(
                      replaceDivsWithNewlines(item.content)
                    )}
                  </span>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TextAreaComponent;
